<template>
  <div class="relative bg-white dark:bg-tl-grey-100 rounded-md" :id="'lineContainer-' + cid">
    <div :id="'lineChart-' + cid" class="flex justify-center"></div>
    <!--div :id="'legend-' + cid" class="absolute top-0 right-4">
      <div class="flex flex-row items-center text-white text-sm font-normal mb-4 last:mb-0">
        <div
          class="w-2.5 h-2.5 rounded-full mr-1.5"
          :style="{ 'background-color': line.colour }"
        ></div>
        <p>{{ line.student }}</p>
      </div>
    </div-->
  </div>
</template>

<script setup lang="ts">
import { useId, onMounted, onUnmounted, toRefs, watch, type PropType, nextTick } from 'vue'
import * as d3 from 'd3'
import type { Speaker } from '@/constants'

export interface LineData {
  x: number
  y: number
}

// The actual chart plot
export interface ChartData {
  key: string
  student: Speaker
  colour: string
  yBounds: [number, number]
  labels: [string, string]
  lines: ValueLine[]
  data: LineData[]
}

// Even rectangles printed over the chart
export interface EventMarker {
  key: string
  start: number
  end: number
  text: string
  colour: string
}

// Special lines printed over the chart
export interface ValueLine {
  axis: 'x' | 'y'
  value: number
  label: string
  colours: string // 'lightModeColour,darkModeColour'
}

const props = defineProps({
  time: { type: Number, required: true },
  valueLines: {
    type: Object as PropType<ValueLine[]>,
    default: () => {
      return []
    }
  },
  line: { type: Object as PropType<ChartData>, required: true }, // Data structure for multiple lines
  darkmode: { type: Boolean, default: false },
  events: {
    type: Object as PropType<EventMarker[]>,
    default: () => {
      return []
    }
  }
})

const { line, time, events, darkmode } = toRefs(props)
const cid = useId()
const margin = 25

let svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>
let aLines: d3.Selection<SVGGElement, unknown, HTMLElement, any>

onMounted(() => {
  if (line.value.data.length) {
    setTimeout(() => setupChart(), 500)
  }
  window.addEventListener('resize', () => {
    nextTick(() => setupChart())
  })
})

onUnmounted(() => {
  window.removeEventListener('resize', setupChart)
})

let currentHeight = 0
let currentWidth = 0

// Scaling
let minX = 0
let maxX = 0
let scaleT: d3.ScaleTime<number, number, never>
let scaleY: d3.ScaleLinear<number, number, never>

function setupChart() {
  d3.select(`#lineChart-${cid}`).select('svg').remove()

  const textColor = darkmode.value ? 'white' : 'black'
  currentHeight = parseInt(d3.select(`#lineContainer-${cid}`).style('height'), 10)
  currentWidth = parseInt(d3.select(`#lineContainer-${cid}`).style('width'), 10)

  const minTime = new Date()
  const maxTime = new Date()

  // Scaling
  const minMax = d3.extent(line.value.data, (d) => d.x)
  minX = minMax[0] ?? 0
  maxX = minMax[1] ?? 0
  minTime.setTime(minX * 1000)
  maxTime.setTime(maxX * 1000)

  scaleT = d3
    .scaleTime()
    .domain([minTime, maxTime])
    .range([0, currentWidth - margin])
    .nice()

  //const [minY, maxY] = d3.extent(line.value.data, (d) => d.y)
  scaleY = d3
    .scaleLinear()
    .domain(line.value.yBounds)
    .range([currentHeight - margin, margin * 2])

  // Main SVG container
  svg = d3
    .select(`#lineChart-${cid}`)
    .append('svg')
    .attr('width', currentWidth + 'px')
    .attr('height', currentHeight + 'px')
    .attr('viewBox', [0, 0, currentWidth, currentHeight])
    .append('g')
    //.attr('transform', `translate(${margin}, ${margin})`)
    .attr('style', 'max-width: 100%; height: auto; overflow: visible; font: 10px sans-serif;')

  // Axis
  const timeFormatter = d3.timeFormat('%M')
  const xAxis = d3.axisBottom<Date>(scaleT).ticks(d3.timeMinute.every(2)).tickFormat(timeFormatter)
  const yAxis = d3.axisLeft(scaleY)

  // Add the X Axis
  svg
    .append('g')
    .attr('class', 'x-axis')
    .attr('transform', 'translate(' + margin * 2 + ',' + (currentHeight - margin * 2) + ')')
    .attr('font-weight', '100')
    .attr('font-family', '"Roboto", "sans-serif"')
    .call(xAxis)
    .selectAll('text')
    .style('fill', textColor)

  // Add the Y Axis
  svg
    .append('g')
    .attr('class', 'y-axis')
    .attr('transform', `translate(${margin * 2}, ${-margin})`)
    .attr('font-weight', '100')
    .attr('font-family', '"Roboto", "sans-serif"')
    .call(yAxis)
    .selectAll('text')
    .style('fill', textColor)
    .append('text')
    .attr('y', 15)
    .attr('transform', 'rotate(-90)')

  // X Axis labels
  svg
    .append('text')
    .attr(
      'transform',
      'translate(' + currentWidth / 2 + ' ,' + (currentHeight - margin * 0.5) + ')'
    )
    .style('text-anchor', 'middle')
    .text(line.value.labels[0])
    .style('fill', textColor)
    .style('font-size', 12)

  // Y Axis labels
  svg
    .append('text')
    .attr('transform', 'translate(' + 0 + ' ,' + currentHeight / 2 + '), rotate(-90)')
    .attr('dy', '1em')
    .style('text-anchor', 'middle')
    .text(line.value.labels[1])
    .style('fill', textColor)
    .style('font-size', 12)

  const lineGenerator = d3
    .line<LineData>()
    .x((d) => {
      const dateFromData = new Date()
      dateFromData.setTime(d.x * 1000)
      return scaleT(dateFromData)
    })
    .y((d) => scaleY(d.y))
    .curve(d3.curveCardinal)

  aLines = svg
    .append('g')
    .attr('class', 'lines')
    .attr('transform', `translate(${margin * 2}, ${-margin})`)

  // Data plot
  aLines
    .selectAll('line-group')
    .data([line.value])
    .enter()
    .append('g')
    .attr('class', 'line-group')
    .append('path')
    .attr('class', 'line')
    .attr('d', (d) => lineGenerator(d.data))
    //.attr('clip-path', 'url("#clipRect")')
    .style('fill', 'none')
    .style('stroke', '#0511F2')
    .style('opacity', 0.9)
    .style('stroke-width', 1)
    .on('mouseover', function () {
      d3.select(this).style('stroke-width', 4)
    })
    .on('mouseout', function () {
      d3.select(this).style('stroke-width', 2)
    })

  // Label plot
  /*  aLines
    .selectAll('.labels')
    .data([line.value])
    .enter()
    .append('g')
    .append('text')
    .attr('clip-path', 'url("#clipRect")')
    .attr('x', function (d) {
      let hori = d.data[d.data.length - 1]['x']
      const dateFromData = new Date()
      dateFromData.setTime(hori * 1000)
      return scaleT(hori) + 5
    })
    .attr('y', function (d) {
      let vert = d.data[d.data.length - 1]['y']
      return scaleY(vert) + 5
    })
    .attr('font-size', '16px')
    .text(function (d) {
      return d.student
    })
    .style('fill', function () {
      return line.value.colour
    })
    .attr('opacity', 1) */

  // Draw Value lines
  aLines.selectAll('.valueLine').remove()
  aLines.selectAll('.valueLineLabel').remove()
  let i = 0
  for (const vl of line.value.lines) {
    let t1 = 0,
      t2 = 0,
      y1 = 0,
      y2 = 0,
      direction = 'V',
      length = 0
    if (vl.axis == 'y') {
      y1 = y2 = scaleY(vl.value)
      t1 = scaleT(minTime)
      t2 = scaleT(maxTime)
      direction = 'H'
      length = t2
    } else {
      const tDate = new Date()
      tDate.setTime(vl.value * 1000)
      t1 = t2 = scaleT(tDate)
      y1 = scaleY(line.value.yBounds[0])
      y2 = scaleY(line.value.yBounds[1])
      direction = 'V'
      length = y2
    }
    const colours = vl.colours.split(',')
    aLines
      .append('path') // attach a line
      .attr('class', 'valueLine')
      .attr('id', function () {
        return `value-line-label-${cid}_` + i
      })
      .attr('stroke-dasharray', 10)
      .style('stroke', props.darkmode ? colours[0] : colours[1]) // colour the line
      //.attr('x1', t1) // x position of the first end of the line
      //.attr('y1', y1) // y position of the first end of the line
      //.attr('x2', t2) // x position of the second end of the line
      //.attr('y2', y2)
      .attr('d', `M ${t1},${y1} ${direction} ${length}`)

    aLines
      .append('text')
      .attr('class', 'valueLineLabel')
      .style('stroke', props.darkmode ? 'white' : 'black')
      .style('stroke-width', 0.5)
      .attr('x', 10) //Move the text from the start angle of the arc
      .attr('dx', -5) //Move the text from the start angle of the arc
      .attr('dy', -5)
      .append('textPath')
      .attr('font-size', '0.8em')
      .attr('letter-spacing', '0.05em')
      .attr('xlink:href', function () {
        return `#value-line-label-${cid}_` + i
      })
      .text(vl.label)
    i++
  }

  drawEventMarkers()
}

// The timeline moves as the time prop is updated
const drawCurrentTimeLine = () => {
  const timeStamp = new Date()
  timeStamp.setTime(time.value * 1000)
  aLines.selectAll('.currentTimeLine').remove()
  // Value line
  aLines
    .append('line') // attach a line
    .attr('class', 'currentTimeLine')
    .style('stroke', props.darkmode ? 'white' : 'black') // colour the line
    .attr('x1', scaleT(timeStamp)) // x position of the first end of the line
    .attr('y1', scaleY(line.value.yBounds[0])) // y position of the first end of the line
    .attr('x2', scaleT(timeStamp)) // x position of the second end of the line
    .attr('y2', scaleY(line.value.yBounds[1]))
}

// Events are drawn as rectangles
const drawEventMarkers = () => {
  aLines.selectAll('.eventBox').remove()
  events.value.forEach((e) => {
    const startTimeStamp = new Date()
    startTimeStamp.setTime(e.start * 1000)
    const endTimeStamp = new Date()
    endTimeStamp.setTime(e.end * 1000)
    const w = scaleT(endTimeStamp) - scaleT(startTimeStamp)
    const h = currentHeight - margin * 3
    const x = scaleT(startTimeStamp)
    const y = scaleY(line.value.yBounds[1])
    // Event Marker Box
    aLines
      .append('rect')
      .attr('class', 'eventBox')
      .attr('width', w)
      .attr('height', h)
      .attr('x', x)
      .attr('y', y)
      .attr('opacity', 0.2)
      .attr('fill', e.colour)
  })
}

watch(
  () => darkmode.value,
  () => setupChart()
)
watch(
  () => line.value,
  () => setupChart()
)
watch(
  () => time.value,
  () => drawCurrentTimeLine()
)
watch(
  () => events.value,
  () => drawEventMarkers()
)
</script>
