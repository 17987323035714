<template>
  <div
    v-if="session"
    class="bg-tl-grey-800 dark:bg-tl-green-100 relative pl-6 py-2 pr-3 w-full rounded-20px flex justify-center flex-row flex-wrap"
  >
    <div v-if="!state.drillDown" class="flex flex-col justify-around items-center w-full">
      <div class="relative self-start text-black dark:text-white mr-8">
        <span class="font-bold">{{ t('Highlighted Events') }}&nbsp;</span>
        <slot name="header" v-if="showInfoBox"></slot>
        <div
          class="rounded-full cursor-pointer absolute -top-0 -right-6 border-2 border-black dark:border-white p-2 w-4 h-4 text-xs text-center font-bold flex items-center justify-center"
          @click="showInfoBox = !showInfoBox"
        >
          i
        </div>
      </div>

      <div class="flex flex-row justify-around items-center w-full mt-8">
        <div
          class="flex flex-col justify-center items-center"
          v-for="(event, index) in sessionGetters.events.value"
          :key="index"
          @click="showDrillDown(event)"
        >
          <div
            class="text-white font-medium bg-slate-700 text-center px-2 py-1 rounded-2xl mb-3 w-32"
          >
            {{ t('event') + `&nbsp;` + (index + 1) }}
          </div>
          <p class="mb-2 text-black dark:text-white">{{ event.description }}</p>
          <div class="flex flex-row">
            <!--NetworkChart
              v-if="state.links[event.id]"
              class="max-h-96 h-96"
              :actors="state.actorData[event.id]"
              :links="state.links[event.id]"
              :showKey="false"
            /-->
            <img
              class="size-80 p-4 rounded invert hue-rotate-180 dark:invert-0 dark:hue-rotate-0"
              :src="`/api/file?group=${session.group}&session=${session.session}&module=Transcript&name=${event.type == EventType.Loop ? 'CL' : 'TL'}${event.oid}.png`"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex flex-col w-full justify-between items-start text-black dark:text-white">
      <div class="flex flex-row items-center self-start font-bold text-xl">
        <div
          class="border rounded-full p-2 bg-gray-50 dark:bg-tl-grey-100 flex flex-row place-items-center h-fit w-fit cursor-pointer"
          @click="returnToOverview()"
        >
          <img class="w-3 h-3 rotate-90 dark:invert" :src="ArrowIcon" />
        </div>
        <h1 class="self-start p-3 font-bold text-xl">
          {{ t('event') + `&nbsp;` + selectedEvent?.name }}&nbsp;:&nbsp;{{
            selectedEvent?.description
          }}
        </h1>
      </div>
      <div class="flex flex-row w-full h-full items-center">
        <TranscriptView
          v-if="selectedEvent"
          :time="state.currentTime"
          :trim="trim"
          :data="state.utterances[selectedEvent.id]"
          :selectedTab="selectedTab"
          class="mr-5 w-full max-h-[400px] xl:max-h-[500px] 2xl:max-h-[600px]"
        />
        <div class="flex flex-row flex-grow items-center justify-around w-1/3">
          <!--NetworkChart
            v-if="selectedEvent"
            class=""
            :showKey="false"
            :actors="state.actorData[selectedEvent.id]"
            :links="state.links[selectedEvent.id]"
          /-->
          <img
            v-if="selectedEvent && selectedEvent.oid"
            class="p-2 rounded invert hue-rotate-180 dark:invert-0 dark:hue-rotate-0 max-h-96"
            :src="`/api/file?group=${session.group}&session=${session.session}&module=Transcript&name=${selectedEvent.type == EventType.Loop ? 'CL' : 'TL'}${selectedEvent.oid}.png`"
          />
        </div>
      </div>
      <VideoModule
        class="mt-4 flex self-center"
        :video-index="1"
        :session="session.session ?? 1"
        :group="sessionGetters.selectedGroup.value?.name ?? 'group1'"
        @timeupdate="(time: number) => (state.currentTime = time)"
      ></VideoModule>
    </div>
  </div>
</template>

<script setup lang="ts">
import { nextTick, onMounted, reactive, ref, watch, type PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { useSessionStore, type ServiceInterface } from '@/composition/stores/sessionStore.js'
import { useAppStore } from '@/composition/stores/appStore'
import TranscriptView, { type TranscriptRow } from '@/components/nursing/TranscriptView.vue'
import { type LinkData, type ActorData } from '@/components/charts/NetworkChart.vue'
import VideoModule from '@/components/nursing/VideoModule.vue'
import type { TLEvent } from '@/models'
import { colorsForActors, EventType, TabName } from '@/constants'
import { parseTLTime } from '@/utilities'
import ArrowIcon from '@/assets/icons/fontawesome/arrow-down-regular.svg'

interface State {
  currentTime: number
  actorData: { [key: string]: ActorData[] }
  links: { [key: string]: LinkData[] }
  utterances: { [key: string]: TranscriptRow[] }
  drillDown: boolean
}

const messages = {
  en: {
    'Highlighted Events': 'Highlighted Events',
    confirmation: 'Confirmation',
    compliance: 'Compliance',
    identification: 'Identification',
    'self-allocation': 'Self-Allocation',
    allocation: 'Allocation',
    'call-out': 'Call-Out',
    'check-back': 'Check-Back',
    bekreftelse: 'Confirmation',
    etterlevelse: 'Compliance',
    identifikasjon: 'Identification',
    egentildeling: 'Self-Allocation',
    oppgavefordeling: 'Allocation',
    callout: 'Call-Out',
    checkback: 'Check-Back'
  },
  no: {
    'Highlighted Events': 'Fremhevede hendelser',
    confirmation: 'Bekreftelse',
    compliance: 'Etterlevelse',
    identification: 'Identifikasjon',
    'self-allocation': 'Egentildeling',
    allocation: 'Oppgavefordeling',
    'call-out': 'Call-Out',
    'check-back': 'Check-Back',
    bekreftelse: 'Bekreftelse',
    etterlevelse: 'Etterlevelse',
    identifikasjon: 'Identifikasjon',
    egentildeling: 'Egentildeling',
    oppgavefordeling: 'Oppgavefordeling',
    callout: 'Call-Out',
    checkback: 'Check-Back'
  }
}

const { t }: { t: (key: string) => string } = useI18n({ messages })
const { getters: appGetters } = useAppStore()

defineProps({
  time: { type: Number, required: true },
  trim: { type: Object as PropType<number[]>, required: true },
  selectedTab: { type: String as PropType<TabName>, required: true }
})

const {
  state: sessionState,
  getters: sessionGetters,
  actions: sessionActions
} = useSessionStore() as ServiceInterface

const state: State = reactive({
  currentTime: 0,
  actorData: {},
  links: {},
  utterances: {},
  drillDown: false
})

onMounted(() => {
  getTabData()
})

const session = sessionGetters.selectedSession
const selectedEvent = sessionGetters.selectedEvent
const showInfoBox = ref(false)

function showDrillDown(event: TLEvent) {
  state.drillDown = true
  sessionActions.setSelectedEvent(event)
}

function returnToOverview() {
  state.drillDown = false
  sessionActions.setSelectedEvent()
}

watch(selectedEvent, () => {
  if (selectedEvent.value) showDrillDown(selectedEvent.value)
  else returnToOverview()
})

watch(sessionGetters.events, () => {
  getTabData()
  //callChartData()
})

watch(appGetters.languageCode, () => {
  nextTick(() => getTabData())
})

const getTabData = () => {
  state.drillDown = false
  const utterances = Array.from(sessionState.csvData)
  sessionGetters.events.value.forEach((event) => {
    const actors: ActorData[] = [] // unique actor names
    const links: LinkData[] = []

    state.utterances[event.id] = utterances
      .slice(event.startRow, 1 + (event.endRow ?? 0))
      .map((d) => {
        const taskCode = t(d['Task Code'].toLowerCase().replace('task', '').trim())
        const loopCode = t(d['Loop Code'].toLowerCase().replace('loop', '').trim())
        return {
          speaker: d.Speaker,
          receiver: d.Receiver,
          utterance: d.Utterance,
          start: parseTLTime(d.Start),
          end: parseTLTime(d.End),
          code: event.type === EventType.Loop ? loopCode : taskCode
        }
      })

    for (let data of event.codes) {
      const speakerKey = data.speaker as keyof typeof colorsForActors
      const receiverKey = data.receiver as keyof typeof colorsForActors
      if (!actors.some((a) => a.id === speakerKey)) {
        actors.push({
          id: speakerKey,
          label: t(speakerKey),
          colour: colorsForActors[speakerKey]?.colour ?? '#AA00BB'
        })
      }
      if (!actors.some((a) => a.id === receiverKey)) {
        actors.push({
          id: receiverKey,
          label: t(receiverKey),
          colour: colorsForActors[receiverKey]?.colour ?? '#AA00BB'
        })
      }

      const cleanedCode = (data.code as string)
        .replace('Task', '')
        .replace('Loop', '')
        .toLowerCase()
        .trim()
      const translatedCode = t(cleanedCode)
      links.push({
        source: speakerKey,
        target: receiverKey,
        label: translatedCode
      })
    }

    // actorData and links are set equally independent of the `selectedEvent` state
    state.actorData[event.id] = actors
    state.links[event.id] = links
  })
}
</script>
