<template>
  <div class="bg-tl-grey-300 dark:bg-tl-green-200 rounded-xl overflow-hidden">
    <div class="text-white border-b-2 flex flex-row">
      <p class="w-20 2xl:w-32 text-center self-start">{{ t('Speaker') }}</p>
      <p class="w-20 2xl:w-32 text-center">{{ t('Receiver') }}</p>
      <p class="text-center flex-grow">{{ t('Utterance') }}</p>
      <p class="w-36 text-center" v-if="selectedTab == TabName.Communication">
        {{ t('Close Loop Code') }}
      </p>
      <p class="w-36 text-center" v-if="selectedTab == TabName.TaskCoordination">
        {{ t('Task Code') }}
      </p>
    </div>
    <div class="overflow-y-auto scroll-smooth max-h-96" ref="scrollingContainer">
      <div
        v-for="(row, index) in data"
        :id="`datarow-${index}`"
        :key="`datarow-${index}`"
        class="scroll-my-4 flex flex-col mt-1 text-xl 2xl:text-2xl"
      >
        <div
          class="flex flex-row text-white bg-tl-green-200 rounded-md"
          :class="[
            index === state.highlightedIndex && highlight
              ? 'bg-tl-grey-200 dark:bg-tl-green-200 opacity-100'
              : 'bg-tl-grey-200 dark:bg-tl-green-200 opacity-50'
          ]"
        >
          <div class="rounded-l-md w-20 2xl:w-32 text-center shrink-0">
            {{ t(row.speaker.toLowerCase()) }}
          </div>
          <div class="w-20 2xl:w-32 text-center shrink-0">{{ t(row.receiver.toLowerCase()) }}</div>
          <div class="flex-grow font-semibold text-center">{{ row.utterance }}</div>
          <div
            class="w-40 shrink-0 flex items-center justify-center text-lg"
            :class="row.code ? 'bg-blue-500' : 'bg-tl-grey-200 dark:bg-tl-green-200'"
          >
            <p>{{ t(row.code.toLowerCase()) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, toRefs, ref, watch, type PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import scrollIntoView from 'smooth-scroll-into-view-if-needed'
import { TabName } from '@/constants'

export interface TranscriptRow {
  speaker: string
  receiver: string
  utterance: string
  code: string
  start: number
  end: number
}

const messages = {
  en: {
    Speaker: 'Speaker',
    Receiver: 'Receiver',
    Utterance: 'Utterance',
    'Close Loop Code': 'Closed-loop Code',
    'Task Code': 'Task Code'
  },
  no: {
    Speaker: 'Taler',
    Receiver: 'Mottaker',
    Utterance: 'Ytring',
    'Close Loop Code': 'Closed-loop Kode',
    'Task Code': 'Oppgavekode'
  }
}

const { t }: { t: (key: string) => string } = useI18n({ messages })

const props = defineProps({
  highlight: { type: Boolean, default: true },
  time: { type: Number, required: true },
  trim: { type: Object as PropType<number[]>, required: true },
  data: { type: Object as PropType<TranscriptRow[]>, required: true },
  selectedTab: { type: String as PropType<TabName>, required: true }
})

const state = reactive({
  duration: 0,
  playerHeight: 300,
  highlightedIndex: 0
})

const { data, highlight } = toRefs(props)

const scrollingContainer = ref()

watch(
  () => props.time,
  (newTime) => {
    moveToSegment(newTime)
  }
)

function moveToSegment(newTime: number) {
  // Assumes segments are sorted by startTime
  const index = data.value.findIndex((s) => newTime >= s.start && newTime <= s.end)
  if (index >= 0) {
    const id = `datarow-${index}`
    const segmentElement = document.getElementById(id)
    state.highlightedIndex = index
    if (segmentElement && scrollingContainer.value) {
      scrollIntoView(segmentElement, {
        ease: (t) => t,
        block: 'center',
        inline: 'nearest',
        boundary: scrollingContainer.value
      })
    }
  }
}
</script>
