import { ref, computed, type ComputedRef, type Ref } from 'vue'
import { type DialogConfig } from '@/models/main.js'
import { LanguageCodes } from '@/constants.js'
import { useI18n } from 'vue-i18n'

// ------------  Types --------------

export interface AppState {
  feedbackMessage: string
  loading: boolean
  fade: boolean
  lastLogin: Date
  // appIsOld: boolean
  disableDelays: boolean
  languageCode: LanguageCodes
  selectedToolMode: string
  selectedCase: string
  darkMode: boolean
  dialogConfig: DialogConfig
}

// ------------  State (internal) --------------
const _appState: Ref<AppState> = ref({
  feedbackMessage: '',
  loading: false,
  fade: false,
  lastLogin: new Date(),
  appIsOld: false,
  languageCode: LanguageCodes.no,
  disableDelays: import.meta.env.VITE_DISABLE_DELAYS === 'true',
  selectedToolMode: '',
  selectedCase: '',
  darkMode: localStorage.getItem('darkMode') === 'true' ? true : false,
  dialogConfig: {
    title: '',
    text: '',
    visible: false,
    cancel: () => ({}),
    cancelText: 'Cancel',
    confirm: () => ({}),
    confirmText: 'Confirm'
  }
})

// ------------  Getters (Read only) --------------
interface Getters {
  fade: ComputedRef<boolean>
  languageCode: ComputedRef<LanguageCodes>
  feedbackMessage: ComputedRef<string>
  selectedCaseMode: ComputedRef<string>
  darkMode: ComputedRef<boolean>
  dialogConfig: ComputedRef<DialogConfig>
}

// ------------  Actions --------------
interface Actions {
  // setDisableDelays: (s: boolean) => void
  setFeedbackMessage: (message: string) => void
  setLoading: (loading: boolean) => void
  toggleDarkMode: () => void
  setLanguageCode: (lang: LanguageCodes) => void
  setFade: (fade: boolean) => void
  setselectedCaseMode: (mode: string) => void
  setDialog: (visible: boolean, config?: DialogConfig) => void
}

// This defines the interface used externally
interface ServiceInterface {
  actions: Actions
  getters: Getters
}
export function useAppStore(): ServiceInterface {
  const { locale } = useI18n({ useScope: 'global' })

  const actions = {
    setDialog(visible: boolean, config?: DialogConfig): void {
      if (config) _appState.value.dialogConfig = config
      _appState.value.dialogConfig.visible = visible
    },
    setFeedbackMessage: function (message: string): void {
      _appState.value.feedbackMessage = message
    },
    toggleDarkMode: function (): void {
      _appState.value.darkMode = !_appState.value.darkMode
      localStorage.setItem('darkMode', _appState.value.darkMode ? 'true' : 'false')
    },
    setLoading: function (loading: boolean): void {
      _appState.value.loading = loading
    },
    setLanguageCode: function (languageCode: LanguageCodes): void {
      _appState.value.languageCode = languageCode
      locale.value = languageCode
    },
    // Fade the display down or up
    setFade: function (fade: boolean): void {
      _appState.value.fade = fade
    },
    setselectedCaseMode: function (mode: string): void {
      _appState.value.selectedCase = mode
    }
  }

  const getters = {
    get darkMode(): ComputedRef<boolean> {
      return computed(() => _appState.value.darkMode)
    },
    get feedbackMessage(): ComputedRef<string> {
      return computed(() => _appState.value.feedbackMessage)
    },
    get languageCode(): ComputedRef<LanguageCodes> {
      return computed(() => _appState.value.languageCode)
    },
    // Current state of the display 'fade'
    get fade(): ComputedRef<boolean> {
      return computed(() => _appState.value.fade)
    },
    // // TESTING ONLY. Remove app delays
    // get disableDelays(): ComputedRef<boolean> {
    //   return computed(() => _appState.value.disableDelays)
    // },
    get selectedCaseMode(): ComputedRef<string> {
      return computed(() => _appState.value.selectedCase)
    },
    get dialogConfig(): ComputedRef<DialogConfig> {
      return computed(() => _appState.value.dialogConfig)
    }
    // get persistedLocalUsers(): ComputedRef<Record<string, LocalUser>> {
    //   return computed(() => _persistedAppState.value.localUsers)
    // },
  }

  return {
    getters,
    actions
  }
}
export type AppStoreType = ReturnType<typeof useAppStore>
//export const AppKey: InjectionKey<UseApp> = Symbol('UseApp')
