<template>
  <div class="flex flex-col p-12 bg-white dark:bg-tl-grey-100">
    <div class="flex flex-row w-full mt-10 items-center">
      <div class="text-black dark:text-white pr-6 flex flex-col">
        <h3 class="mt-16 text-xl xl:text-2xl font-medium text-tl-purple-400">
          Welcome to Teamlearn
        </h3>
        <h1 class="text-7xl xl:text-7xl 2xl:text-8xl mt-4">
          Track and analyze your group's collaboration!
        </h1>
        <p class="text-xl mt-4 text-tl-grey-300">
          Welcome to the dashboard for students in TeamLearn research project! Log in to access your
          group’s collaboration data.
        </p>
        <br />
        <div
          class="w-fit cursor-pointer bg-tl-purple-400 text-black mt-4 h-14 rounded-40px px-6 flex flex-none justify-center items-center text-xl font-medium"
          @click="login()"
        >
          Logg in
        </div>
      </div>
      <div>
        <img class="rounded-40px" :src="frontPageImage" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { LoginType } from '@/interfaces'
import { serverBaseUrl } from '@/constants'
import frontPageImage from '@/assets/frontpage.jpg'

function login() {
  window.location.href = `${serverBaseUrl}/auth/${LoginType.Feide}`
}
</script>
