<template>
  <div class="flex flex-col pt-4 bg-white dark:bg-tl-grey-100 grow">
    <div v-if="router.currentRoute.value.name != 'Home'" class="mx-3 flex flex-row items-center">
      <div
        class="text-black dark:text-white bg-tl-grey-900 dark:bg-tl-grey-200 w-10 h-10 rounded-full flex justify-center items-center cursor-pointer"
        @click="navigateToCaseDashboard()"
      >
        <img class="w-3 rotate-90 dark:invert" :src="ArrowIcon" />
      </div>
    </div>
    <div class="text-4xl m-4 mt-10 text-black dark:text-white">
      <p>About</p>
      <br />
      <p>
        This dashboard visualizes data from student groups activities which are part of different
        study programs: Law, Nursing, Medicine, Pharmacy, Psycholoy, Theology, Logopedy, and
        Nutrition education.
      </p>
      <br />
      <p>
        The dashboard is developed by the research project TeamLearn. The project examines how
        students collaborate and learn in project groups and simulation teams.
      </p>
      <br />
      <p>
        The data from group work is used to help student groups to reflect on their collaboration
        and to improve their collaboration skills for the future.
      </p>
      <br />
      <p class="text-3xl">
        ↘ If you want to know more about the TeamLearn project, visit the project webpage or
        contact crina.damsa@iped.uio.no
      </p>
    </div>
    <div class="flex flex-grow"></div>
    <div class="flex flex-row text-xl align-self-end m-4 text-black dark:text-white">
      <p>Crina Damsa<br />Professor - Institutt for pedagogikk</p>
      <p class="ml-10">+47 22840725<br />crina.damsa@iped.uio.no</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import ArrowIcon from '@/assets/icons/fontawesome/arrow-down-regular.svg'
const router = useRouter()

function navigateToCaseDashboard() {
  router.push('/dashboard')
}
</script>

<style></style>
