<template>
  <div
    class="flex flex-col items-start bg-white dark:bg-tl-grey-100 text-black dark:text-white h-full"
  >
    <div class="p-11 grid grid-cols-3 grid-flow-row gap-4" v-if="modulesVisible[0]">
      <div
        class="flex flex-col items-center w-full bg-gray-100 dark:bg-slate-800 rounded-lg p-2"
        v-for="(group, index) in groups"
        :key="index"
      >
        <p class="capitalize text-3xl">{{ group.name }}</p>
        <div class="flex flex-col justify-between flex-wrap pt-4 w-full">
          <div
            class="py-4 m-2 mb-7 px-5 rounded-xl flex justify-center flex-col border-2 bg-gray-200 dark:bg-tl-grey-200"
            v-for="(session, sindex) in group.sessions"
            :key="sindex"
          >
            <div v-if="session.exists">
              <div v-if="!session.status.inProgress">
                <div class="flex flex-row items-center justify-between">
                  <p class="text-lg">Session {{ sindex + 1 }}</p>
                  <div
                    class="rounded ml-3 text-xs flex items-center px-2 py-1.5 text-white"
                    :class="statusClass(session.status.pipeline)"
                  >
                    <img class="w-4 h-4 invert mr-2" :src="CheckIcon" />
                    <p>{{ session.status.pipeline }}</p>
                  </div>
                  <p
                    v-if="isAdmin"
                    class="text-2xl ml-2"
                    @click="
                      modulesVisible[index][sindex as MVKey] =
                        !modulesVisible[index][sindex as MVKey]
                    "
                  >
                    {{ modulesVisible[index][sindex as MVKey] ? '◉' : '◎' }}
                  </p>
                </div>
                <div class="flex flex-col mt-4" v-if="modulesVisible[index][sindex as MVKey]">
                  <p class="text-xs">
                    Most recent process took
                    {{ Math.round(session.status.lastCompletionTime) }} seconds
                  </p>
                  <div
                    v-for="(mod, index2) in Object.keys(session.status.modules)"
                    :key="index2"
                    class="flex flex-col mt-1.5 border-2 p-3 rounded shadow justify-around items-center bg-gray-100 dark:bg-tl-grey-300"
                  >
                    <div class="flex flex-row justify-between w-full">
                      <p>{{ mod }}</p>
                      <div
                        class="rounded-3xl ml-3 text-xs flex items-center p-2 text-white justify-center"
                        :class="statusClass(Object.values(session.status.modules)[index2])"
                      >
                        <img
                          v-if="
                            Object.values(session.status.modules)[index2] ===
                            PipelineSubStatus.Ready
                          "
                          class="w-3 h-3 invert"
                          :src="CheckIcon"
                        />
                        <img v-else class="w-3 h-3 invert" :src="CrossIcon" />
                        <!--<p>{{ Object.values(session.status.modules)[index] }}</p>-->
                      </div>
                    </div>
                    <div class="flex flex-row justify-start w-full pt-2">
                      <div
                        @click="callForUpdate(session.id, 'modules', mod)"
                        class="flex flex-row text-xs w-full justify-center rounded border-2 p-1.5 bg-white dark:bg-tl-grey-100 cursor-pointer"
                      >
                        <img class="w-4 h-4 mr-2 dark:invert" :src="RetryIcon" />
                        <p>Re-run module</p>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="session.status.message && session.status.error"
                    @click="errorIsVisible[index] = !errorIsVisible[index]"
                    class="m-3 bg-tl-traffic-cdisagree text-white py-px px-2 rounded-md cursor-pointer text-xs text-center"
                  >
                    <p>error</p>
                  </div>
                  <div
                    v-if="session.status.message && errorIsVisible[index]"
                    class="max-w-56 bg-gray-600 p-2 text-xs text-wrap break-words"
                    :text="session.status.message"
                  >
                    <p>{{ session.status.message }}</p>
                  </div>
                  <div
                    class="flex justify-center rounded border-2 my-2 p-2 text-xs cursor-pointer bg-white dark:bg-tl-grey-100"
                    @click="callForUpdate(session.id, 'sync')"
                  >
                    <img class="w-4 h-4 mr-2 dark:invert" :src="RetryIcon" />
                    <p>Re-run sync</p>
                  </div>
                  <div
                    class="flex justify-center rounded border-2 border-red-500 my-2 p-2 text-xs cursor-pointer bg-white dark:bg-tl-grey-100"
                    @click="callForUpdate(session.id, 'all')"
                  >
                    <img class="w-4 h-4 mr-2 dark:invert" :src="RetryIcon" />
                    <p>Re-run entire pipeline</p>
                  </div>
                </div>
                <div
                  class="mt-4 flex justify-center rounded-md border-2 p-2 text-xs cursor-pointer bg-white dark:bg-tl-grey-100"
                  @click="selectSession(session, group)"
                >
                  <p>Open Session</p>
                </div>
              </div>
              <div v-else class="flex flex-row">
                <PulseLoader :size="'8px'" class="mr-3" />
                <p>Processing in progress..</p>
              </div>
            </div>
            <div v-else>Remote Session Data Not Found</div>
          </div>
        </div>
        <div
          v-if="isAdmin && group.sessions.length < 2"
          class="border-2 text-xs m-6 p-2 cursor-pointer rounded-md"
          @click="addSession(group.name)"
        >
          Add session
        </div>
        <div
          v-if="isAdmin"
          class="mt-4 flex justify-center rounded border-2 p-2 text-xs cursor-pointer bg-white dark:bg-tl-grey-100"
          @click="getRegistrationLink(group)"
        >
          <img class="w-4 h-4 mr-2 dark:invert" :src="LinkIcon" />
          <p>Copy registration link</p>
        </div>
      </div>
    </div>
    <div class="p-20 flex flex-col justify-center items-center" v-if="isAdmin">
      <p class="capitalize text-3xl mb-11 text-gray-500">(New group)</p>
      <div
        class="flex justify-center rounded border-2 p-2 text-xs cursor-pointer"
        @click="addSession(`group${groups.length + 1}`)"
      >
        <p>Add a new group and session</p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, type Ref } from 'vue'
import { useRouter } from 'vue-router'

import { useSessionStore } from '@/composition/stores/sessionStore'
import { useUserStore } from '@/composition/stores/userStore'
import { useAppStore } from '@/composition/stores/appStore'
import useDialogStore from '@/composition/stores/dialogStore'

import type { NursingGroup } from '@/models'
import { UserRole, type Session } from '@database'
import { ModuleType, PipelineSubStatus } from '@models'
import { PipelineStatus, serverBaseUrl } from '@/constants'

import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import CheckIcon from '@/assets/icons/fontawesome/circle-check-solid.svg'
import CrossIcon from '@/assets/icons/fontawesome/circle-xmark-solid.svg'
import RetryIcon from '@/assets/icons/fontawesome/rotate-right-solid.svg'
import LinkIcon from '@/assets/icons/fontawesome/link.svg'
import { DialogMessageType, LoginType } from '@/interfaces'

type ModuleVisibility = { 0: boolean; 1: boolean }
type MVKey = keyof ModuleVisibility

const sessionStore = useSessionStore()
const router = useRouter()
const userStore = useUserStore()
const { actions: appActions } = useAppStore()
const dialogStore = useDialogStore()
let updateInterval: NodeJS.Timeout
const groups = sessionStore.getters.groups
const errorIsVisible: Ref<boolean[]> = ref(new Array(groups.value?.length).fill(false))
const modulesVisible: Ref<Record<number, ModuleVisibility>> = ref({})

function updateGroupsInterval() {
  updateInterval = setInterval(async () => {
    await sessionStore.actions.getGroupsForUser()
  }, 10000)
}

const callForUpdate = async (sessionID: string, mode: string = 'sync', module?: string) => {
  if (mode == 'all') {
    appActions.setDialog(true, {
      title: 'Confirm Restart',
      text: 'The pipeline can take a long time to complete. Please confirm you want to run it again from the beginning',
      visible: true,
      confirm: async () => {
        appActions.setDialog(false)
        await sessionStore.actions.runPipeline(sessionID, mode, [module as ModuleType])
        await sessionStore.actions.getGroupsForUser()
      },
      confirmText: 'Continue',
      cancel: () => appActions.setDialog(false),
      cancelText: 'Cancel'
    })
  } else {
    await sessionStore.actions.runPipeline(sessionID, mode, [module as ModuleType])
    await sessionStore.actions.getGroupsForUser()
  }
}

onMounted(async () => {
  await sessionStore.actions.getGroupsForUser()
  updateGroupsInterval()
  modulesVisible.value = new Array(groups.value?.length).fill({ 0: false, 1: false })
  groups.value.forEach((_, i) => (modulesVisible.value[i] = { 0: false, 1: false }))
})

onBeforeUnmount(() => clearInterval(updateInterval))

const isAdmin = computed(() => {
  const user = userStore.getters.user.value
  return user ? user.profile.role === UserRole.admin : false
})

const selectSession = (session: Session, group: NursingGroup) => {
  sessionStore.actions.setSelectedGroup(group)
  sessionStore.actions.setSelectedSession(session)
  clearInterval(updateInterval)
  router.push('/nursing')
}

const addSession = async (groupName: string) => {
  await sessionStore.actions.createSession(groupName)
  await sessionStore.actions.getGroupsForUser() // update groups
}

const statusClass = (status: string) => {
  switch (status) {
    case PipelineStatus.Cleaned:
      return 'bg-tl-traffic-disagree'
    case PipelineStatus.Copying:
      return 'bg-tl-traffic-neutral'
    case PipelineStatus.Original:
      return 'bg-tl-traffic-agree'
    case PipelineStatus.Ready:
      return 'bg-green-600'
    default:
      return 'bg-tl-lilla-400'
  }
}

async function getRegistrationLink(group: NursingGroup) {
  const response = await sessionStore.actions.getGroupLink(group.name)
  if (response.data) {
    const link = `${serverBaseUrl}/auth/${LoginType.Feide}?regcode=${group.name}:${response.data}`
    await navigator.clipboard.writeText(link)
    dialogStore.actions.pushMessage('Link copied!', DialogMessageType.Information, 'Link copied!')
  }
}
</script>
