const globalTranslations = {
  en: {
    event: 'Event',
    created: 'created',
    dashboard: 'Dashboard',
    analytics: 'Analytics',
    ln: 'LN',
    sn1: 'SN1',
    sn2: 'SN2',
    sn3: 'SN3',
    as: 'LN',
    s1: 'SN1',
    s2: 'SN2',
    s3: 'SN3',
    ln_fullname: 'Lead Nurse',
    sn1_fullname: 'Support Nurse 1',
    sn2_fullname: 'Support Nurse 2',
    sn3_fullname: 'Support Nurse 3',
    as_fullname: 'Lead Nurse',
    s1_fullname: 'Support Nurse 1',
    s2_fullname: 'Support Nurse 2',
    s3_fullname: 'Support Nurse 3',
    s5_fullname: 'Student 4',
    s6_fullname: 'Student 5',
    none: 'None',
    doctor: 'Doctor',
    lege: 'Doctor',
    patient: 'Patient',
    pasient: 'Patient',
    teacher: 'Teacher',
    lærer: 'Teacher',
    external: 'External',
    utvendig: 'External'
  },
  no: {
    event: 'Hendelse',
    created: 'opprettet',
    dashboard: 'Dashboard',
    analytics: 'Analytics',
    ln: 'AS',
    sn1: 'S1',
    sn2: 'S2',
    sn3: 'S3',
    as: 'AS',
    s1: 'S1',
    s2: 'S2',
    s3: 'S3',
    ln_fullname: 'Ansvarlig sykepleier',
    sn1_fullname: 'Assisterende sykepleier 1',
    sn2_fullname: 'Assisterende sykepleier 2',
    sn3_fullname: 'Assisterende sykepleier 3',
    as_fullname: 'Ansvarlig sykepleier',
    s1_fullname: 'Assisterende sykepleier 1',
    s2_fullname: 'Assisterende sykepleier 2',
    s3_fullname: 'Assisterende sykepleier 3',
    s5_fullname: 'Student 4',
    s6_fullname: 'Student 5',
    none: 'Ingen',
    doctor: 'Lege',
    lege: 'Lege',
    patient: 'Pasient',
    pasient: 'Pasient',
    teacher: 'Lærer',
    lærer: 'Lærer',
    external: 'Utvendig',
    utvendig: 'Utvendig'
  }
}

export { globalTranslations }
