<template>
  <div class="flex flex-col mx-16">
    <div class="mt-2 mb-3">
      <table class="w-full text-black dark:text-white">
        <thead>
          <tr class="border-none uppercase">
            <th class="float-left text-xs font-semibold p-3">Question</th>
            <th
              class="text-xs font-semibold p-3"
              v-for="(user, index) in displayNames"
              :key="index"
            >
              {{ user }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(qa, qI) in compiledAnswers"
            :key="qI"
            class="border"
            :class="{
              'border-red-500 bg-red-500 bg-opacity-30': qa.highlight === 'red',
              'border-green-500 bg-green-500 bg-opacity-30': qa.highlight === 'green'
            }"
          >
            <td class="p-3" v-html="qa.question"></td>
            <td v-for="(answer, uI) in qa.answers" :key="uI">
              <div
                class="w-6 h-6 bg-tl-traffic-agree rounded-full flex justify-self-center self-center"
                :class="{
                  'bg-tl-traffic-cagree': answer.answer === 'cCompletelyAgree',
                  'bg-tl-traffic-agree': answer.answer === 'cAgree',
                  'bg-tl-traffic-neutral': answer.answer === 'cNeither',
                  'bg-tl-traffic-disagree': answer.answer === 'cDisagree',
                  'bg-tl-traffic-cdisagree': answer.answer === 'cCompletelyDisagree'
                }"
                :title="answerToText[answer.answer as keyof typeof answerToText]"
              ></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex mt-3">
      <div v-for="(rating, index) in ratings" :key="index" class="flex items-center mr-4">
        <div class="w-4 h-4 rounded-full mr-2" :class="rating.color"></div>
        <p class="text-black dark:text-white">{{ rating.value }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue'

import { useSurveyStore } from '@/composition/stores/surveyStore.js'
import { reMapNames } from '@/utilities'

enum ExperienceLegend {
  CompletelyAgree = 'CompletelyAgree',
  Agree = 'Agree',
  Neutral = 'Neutral',
  Disagree = 'Disagree',
  CompletelyDisagree = 'CompletelyDisagree'
}

interface ExperienceRating {
  color: string
  value: ExperienceLegend
}

interface AA {
  answer: string // cAgree, cCompletelyAgree, etc..
  user: string
}

interface QAU {
  question: string
  answers: AA[]
  highlight: string // 'none', 'red' or 'green'
}

const ratings: ExperienceRating[] = [
  { color: 'bg-tl-traffic-cagree', value: ExperienceLegend.CompletelyAgree },
  { color: 'bg-tl-traffic-agree', value: ExperienceLegend.Agree },
  { color: 'bg-tl-traffic-neutral', value: ExperienceLegend.Neutral },
  { color: 'bg-tl-traffic-disagree', value: ExperienceLegend.Disagree },
  { color: 'bg-tl-traffic-cdisagree', value: ExperienceLegend.CompletelyDisagree }
]

const answerToText = {
  cCompletelyAgree: 'Completely Agree',
  cAgree: 'Agree',
  cNeither: 'Neutral',
  cDisagree: 'Disagree',
  cCompletelyDisagree: 'Completely Disagree'
}

const surveyStore = useSurveyStore()

const props = defineProps<{ selectedTab: string }>()
const { selectedTab } = toRefs(props)

const users = surveyStore.getters.users
const questions = surveyStore.getters.questions
const answers = surveyStore.getters.answers

const formUsers = computed(() =>
  users.value
    .filter((u) => u.nettskjemaIds.includes(selectedTab.value))
    .sort((a, b) => {
      if (a.user < b.user) return -1
      if (a.user > b.user) return 1
      return 0
    })
)

const userNameMap = computed(() => {
  const userNames = formUsers.value.map((u) => u.user)
  return reMapNames(userNames)
})

const displayNames = computed(() => {
  return formUsers.value.map((u) => userNameMap.value[u.user] ?? '')
})

const compiledAnswers = computed(() => {
  const compiledAnswers: QAU[] = []
  const qs = questions.value.filter((q) => q.nettskjemaId === selectedTab.value)
  const as = answers.value.filter((a) => a.nettskjemaId === selectedTab.value)

  for (const question of qs) {
    const q: QAU = { question: question.questionText, answers: [], highlight: 'none' }

    for (const u of formUsers.value) {
      const answer = as.find((a) => a.userName === u.user && a.questionId === question.questionId)
      if (answer) {
        const displayName = userNameMap.value[u.user] ?? ''
        q.answers.push({ answer: answer.text ?? '', user: displayName })
      }
    }

    q.highlight = q.answers.every((a) => a.answer === 'cCompletelyAgree') ? 'green' : 'none'
    q.highlight = q.answers.every((a) => a.answer === 'cCompletelyDisagree') ? 'red' : 'none'
    compiledAnswers.push(q)
  }

  return compiledAnswers
})

/* const questionColor = (userName: string, id: number, index: number) => {
  const text = compileAnswers.value.filter(
    (a) => a.userName === userName && a.nettskjemaId === id.toString()
  )[index]?.chosenAnswers[0]
  // choose ' index ' of array to get the right question answer
  if (text?.includes('cAgree')) return 'bg-tl-traffic-agree'
  if (text?.includes('cCompletelyAgree')) return 'bg-tl-traffic-cagree'
  if (text?.includes('cNeitherAgreeNorDisagree')) return 'bg-tl-traffic-neutral'
  if (text?.includes('cDisagree')) return 'bg-tl-traffic-disagree'
  if (text?.includes('cCompletelyDisagree')) return 'bg-tl-traffic-cdisagree'
} */
</script>
