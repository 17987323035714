// useCMSStore are the same across projects,
// however they provide the junction to each project's unique data structures in neighboring directories
import { ref, computed } from 'vue'
import type { Ref, ComputedRef } from 'vue'
import useDialogStore from '@/composition/stores/dialogStore.js'
import type { CmsGQLQuery, CmsGQLData, IPromptSchema } from '@frontend'
import { PromptSchema } from '@frontend'
import useApiService from '@/apiRequest.js'
import {
  cmsUrl,
  cmsClientId,
  cmsClientSecret,
  cmsTokenUrl,
  LanguageFallbacks,
  LanguageCodes,
  XHR_REQUEST_TYPE,
  XHR_CONTENT_TYPE,
  ErrorCode
} from '@/constants.js'

interface TokenResponse {
  access_token: string
  expires_in: number
  token_type: string
  scope: string
}

// ------------  State (internal) --------------
interface State {
  promptSchema: Map<string, PromptSchema>
  selectedDocumentPrompt: PromptSchema | undefined
  // selectedMeetingPrompt:PromptSchema|undefined
}

const dialogStore = useDialogStore()
const apiService = useApiService()

const prompt = `query prompt {results: queryPromptschemaContents {id data {instruction {iv}explanation {iv}prompt {iv}promptSection {iv}promptType {iv}}}}`

const state: Ref<State> = ref({
  promptSchema: new Map(),
  selectedDocumentPrompt: undefined
  // selectedMeetingPrompt:undefined
})
// ------------  Internal functions ------------

async function fetchToken() {
  if (cmsUrl.includes('localhost')) return ''
  const response = await apiService.apiRequest<TokenResponse>({
    errorCode: ErrorCode.SQUIDEX,
    route: '',
    method: XHR_REQUEST_TYPE.POST, // or 'PUT'
    credentials: false,
    contentType: XHR_CONTENT_TYPE.URLENCODED,
    body: `grant_type=client_credentials&client_id=${cmsClientId}&client_secret=${cmsClientSecret}&scope=squidex-api`,
    baseURL: `${cmsTokenUrl}`
  })
  if (response.error) return dialogStore.actions.handleError(response)
  return response.data?.access_token ?? ''
}

async function cmsRequest(
  projectName: string,
  query: string,
  replacables: Record<string, string | number>,
  language: LanguageCodes
): Promise<CmsGQLQuery | undefined> {
  const token = localStorage.getItem('squidex-token')
  const fallbacks = LanguageFallbacks[language].reduce((prev, curr) => prev + ',' + curr, '')

  // Squidex does not support standard a GraphQL query body!
  // const body = JSON.stringify({ query, variables }),
  Object.keys(replacables).forEach((key: string) => {
    const regex = new RegExp(key, 'g')
    const v = replacables[key].toString()
    query = query.replace(regex, v)
  })

  const variables = {}

  // NOTE: MUST use the name 'query' here
  const body = { query, variables }

  // NOTE: apiRequest does not reject, it only resolves (possibly with an error code)
  const res = await apiService.apiRequest<CmsGQLQuery>({
    errorCode: ErrorCode.SQUIDEX,
    route: `/api/content/${projectName}/graphql`,
    method: XHR_REQUEST_TYPE.POST,
    credentials: false,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Languages': `${language}${fallbacks}`
    },
    body,
    contentType: XHR_CONTENT_TYPE.JSON,
    baseURL: `${cmsUrl}`
  })

  const error = res.error
  if (error) {
    if (error.status === 401) {
      console.log('CMS Unauthorized. Attempting to fetch new token ...' + error.message)
      const newToken = await fetchToken()
      if (newToken) {
        localStorage.setItem('squidex-token', newToken)
        // Attempt to make the same call again with a new token
        return cmsRequest(projectName, query, variables, language)
      } else console.log(new Error('New Token retrieval error: ' + error.message))
    } else console.log(new Error('CMS request error: ' + error.message))
  }
  return res.data
}

async function fetchPromptSchema(): Promise<CmsGQLQuery | undefined> {
  // const variables = {}
  return cmsRequest('legaltech', prompt, {}, LanguageCodes.en)
}
// ------------  Getters --------------
interface Getters {
  prompts: ComputedRef<Map<string, PromptSchema>>
  documentPrompt: ComputedRef<PromptSchema | undefined>
}

const getters = {
  get prompts(): ComputedRef<Map<string, PromptSchema>> {
    return computed(() => state.value.promptSchema)
  },
  get documentPrompt(): ComputedRef<PromptSchema | undefined> {
    return computed(() => state.value.selectedDocumentPrompt)
  }
}

// ------------  Actions --------------
interface Actions {
  getPrompts: () => Promise<Map<string, PromptSchema>>
}
const actions = {
  getPrompts: async function (): Promise<Map<string, PromptSchema>> {
    const response = await fetchPromptSchema()
    if (response && response.data) {
      const results = response.data.results as CmsGQLData[]
      results.map((r) => {
        const obj = { id: r.id, ...r.data }
        const promptData = new PromptSchema(obj as unknown as IPromptSchema)
        state.value.promptSchema.set(promptData.promptType + promptData.promptSection, promptData)
      })
    } else {
      const error = 'Sett query contains no records'
      dialogStore.actions.pushMessage(error)
      console.log(error)
    }

    return Promise.resolve(state.value.promptSchema)
  }
}

// This defines the interface used externally
interface ServiceInterface {
  actions: Actions
  getters: Getters
}
export function useCMSStore(): ServiceInterface {
  return {
    getters,
    actions
  }
}

export type CMSStoreType = ReturnType<typeof useCMSStore>
// export const UserKey: InjectionKey<UseUser> = Symbol('UseUser')
