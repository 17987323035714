import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import { serverBaseUrl } from '@/constants.js'

import HomeView from '@/views/HomeView.vue'
import AboutView from '@/views/AboutView.vue'
import AdminView from '@/views/AdminView.vue'
import LoginView from '@/views/LoginView.vue'
import Registration from '@/views/RegistrationView.vue'
import ProjectChoice from '@/views/ProjectChoice.vue'

// --------  Nursing ----------
import NursingDashboard from '@/views/nursing/DashboardView.vue'
import NursingGroups from '@/views/nursing/GroupView.vue'
import HomeTab from '@/views/nursing/tabs/HomeTab.vue'
import TaskLoopTab from '@/views/nursing/tabs/TaskLoopTab.vue'
import StressManagementTab from '@/views/nursing/tabs/StressManagementTab.vue'

// --------  Legal ----------
import LegalDashboard from '@/views/legal/NewDashboardView.vue'
import TeamSelection from '@/views/legal/TeamSelection.vue'
import AnalyticsView from '@/views/legal/AnalyticsView.vue'
import MeetingAnalytics from '@/views/legal/analytics/MeetingAnalytics.vue'
import CollaborationExperience from '@/views/legal/analytics/CollaborationExperience.vue'
import WorkDocuments from '@/views/legal/analytics/WorkDocuments.vue'

// --------------------------
import { useUserStore } from '@/composition/stores/userStore.js'
import { TabName } from '@/constants.js'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/about',
    name: 'About',
    component: AboutView
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration
  }
]

const authRoutes: Array<RouteRecordRaw> = [
  {
    path: '/dashboard',
    name: 'ProjectChoice',
    component: ProjectChoice
  },
  {
    path: '/nursing/groups',
    name: 'NursingGroups',
    component: NursingGroups
  },
  {
    path: '/nursing/admin',
    name: 'NursingAdmin',
    component: AdminView
  },
  {
    path: '/nursing',
    name: 'Nursing',
    component: NursingDashboard,
    children: [
      {
        path: '',
        name: TabName.General,
        component: HomeTab
      },
      {
        path: 'taskcoordination',
        name: TabName.TaskCoordination,
        component: TaskLoopTab
      },
      {
        path: 'communication',
        name: TabName.Communication,
        component: TaskLoopTab
      },
      {
        path: 'stressmanagement',
        name: TabName.StressManagement,
        component: StressManagementTab
      }
    ]
  },
  {
    path: '/legal',
    name: 'Legal',
    component: TeamSelection
  },
  {
    path: '/legal/dashboard',
    name: 'Dashboard',
    component: LegalDashboard
  },
  {
    path: '/legal/admin',
    name: 'LegalAdmin',
    component: AdminView
  },
  {
    path: '/legal/analytics',
    name: 'LegalAnalytics',
    component: AnalyticsView,
    children: [
      {
        path: 'MeetingAnalytics',
        name: 'MeetingAnalytics',
        component: MeetingAnalytics
      },
      {
        path: 'CollaborationExperience',
        name: 'CollaborationExperience',
        component: CollaborationExperience
      },
      {
        path: 'WorkDocuments',
        name: 'WorkDocuments',
        component: WorkDocuments
      }
    ]
  }
]

// Apparently Cordova does not work in browser 'history' mode
const isRunningOnELServer = serverBaseUrl.includes('engagelab') || serverBaseUrl.includes('uvapps')
const baseURL = isRunningOnELServer ? import.meta.env.BASE_URL : '/'
const routerType = createWebHistory(baseURL)
const userStore = useUserStore()

const router = createRouter({
  history: routerType,
  routes: [...routes, ...authRoutes]
})

export const authRouteNames = authRoutes.flatMap((route) => {
  const cs = route.children?.map((c) => c.name) ?? []
  return cs.concat(route.name)
})

router.beforeEach(async (to) => {
  // Check that the user is authorised
  const routeRequiresAuth = authRouteNames.some((ar) => ar == to.name)
  let validLogin = userStore.getters.user.value !== undefined
  if (routeRequiresAuth && !validLogin) {
    await userStore.actions.getUserDetails()
    validLogin = userStore.getters.user.value !== undefined
  }

  if (routeRequiresAuth && !validLogin) {
    router.push({ name: 'Home' })
  }
})

// Periodically check user's login validity
const loginCheckInterval = setInterval(async () => {
  console.log('Checking login..')
  const currentRoute = router.currentRoute.value.name
  const routeRequiresAuth = authRouteNames.some((ar) => ar == currentRoute)

  const status = await userStore.actions.getUserDetails()
  if (routeRequiresAuth && status.error) {
    clearInterval(loginCheckInterval)
    router.push({ name: 'Home' })
  }
}, 3600 * 1000) // 1 hour

export default router
