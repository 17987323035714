<template>
  <div :id="'pieContainer-' + cid" class="m-2 flex flex-row items-center w-full justify-around">
    <div :id="'legend' + cid">
      <div
        v-for="entry in legend"
        :key="entry.name"
        class="flex flex-row items-center text-white text-xs xl:text-sm font-normal mb-2 lg:mb-4 last:mb-0"
      >
        <!-- entry.colour can be a tailwind class or #colour value -->
        <div
          class="w-2.5 h-2.5 rounded-full"
          :class="entry.colour"
          :style="{ 'background-color': entry.colour }"
        ></div>
        <p class="text-black dark:text-white text-center">{{ entry.name }}</p>
      </div>
    </div>
    <div :id="'pieChart-' + cid"></div>
  </div>
</template>

<script setup lang="ts">
import { useId, computed, onMounted, onUnmounted, toRefs, type PropType, watch } from 'vue'
import * as d3 from 'd3'

export interface PieData {
  name: string // Name is used as a legend label
  value: number
}

const props = defineProps({
  data: { type: Object as PropType<PieData[]>, required: true },
  colours: { type: Object as PropType<Record<string, string>>, required: true },
  units: { type: String, default: 'percent' } // 'percent' or 'absolute'
})

const { data, colours, units } = toRefs(props)
const cid = useId()
const colour = (d: PieData) => colours.value[d.name] ?? '#AABBCC'

const legend = computed(() =>
  data.value.map((entry) => ({ name: entry.name, colour: colour(entry) }))
)

onMounted(() => {
  if (data.value.length) setupChart()
  window.addEventListener('resize', setupChart)
})

onUnmounted(() => {
  window.addEventListener('resize', setupChart)
})

watch(data, () => {
  setupChart()
})

/**
 * Calculate brightness value by RGB or HEX color.
 * @param color (String) The color value in RGB or HEX (for example: #000000 || #000 || rgb(0,0,0) || rgba(0,0,0,0))
 * @returns (Number) The brightness value (dark) 0 ... 255 (light)
 */
function brightnessByColor(c: string) {
  let r = 0,
    g = 0,
    b = 0
  const color = '' + c,
    isHEX = color.indexOf('#') == 0,
    isRGB = color.indexOf('rgb') == 0
  if (isHEX) {
    const hasFullSpec = color.length == 7
    var m = color.substring(1).match(hasFullSpec ? /(\S{2})/g : /(\S{1})/g)
    if (m)
      (r = parseInt(m[0] + (hasFullSpec ? '' : m[0]), 16)),
        (g = parseInt(m[1] + (hasFullSpec ? '' : m[1]), 16)),
        (b = parseInt(m[2] + (hasFullSpec ? '' : m[2]), 16))
  }
  if (isRGB) {
    const m = color.match(/(\d+){3}/g)
    if (m) {
      r = parseInt(m[0])
      g = parseInt(m[1])
      b = parseInt(m[2])
    }
  }
  if (typeof r != 'undefined') return (r * 299 + g * 587 + b * 114) / 1000
}

function setupChart() {
  d3.select(`#pieChart-${cid}`).select('svg').remove()

  const container = d3.select(`#pieContainer-${cid}`)
  const h = container.style('height') ?? 0
  const currentHeight = parseInt(h, 10)
  const currentWidth = currentHeight
  const margin = currentHeight * 0.05
  const radius = Math.min(currentWidth, currentHeight) / 2 - margin
  let valueSum = 0

  const svg = d3
    .select(`#pieChart-${cid}`)
    .append('svg')
    .attr('width', currentWidth)
    .attr('height', currentWidth)
    .attr('viewBox', [0, 0, currentWidth, currentWidth])
    .append('g')
    .attr('transform', 'translate(' + currentWidth / 2 + ',' + currentWidth / 2 + ')')
    .attr('style', 'max-width: 100%; height: auto; overflow: visible; font: 10px sans-serif;')

  const pie = d3.pie<PieData>().value((d: PieData) => d.value)
  const arc = d3.arc<d3.PieArcDatum<PieData>>().innerRadius(0).outerRadius(radius)
  const parsedData = pie(data.value)

  data.value.forEach((d) => (valueSum += d.value))

  // Compute the position of each group on the pie:

  const g = svg.selectAll('.arc').data(parsedData).enter().append('g').attr('class', 'arc')

  // A separate arc for text display
  const radiusScaleFactor = data.value.length < 5 ? 1.5 : 1
  const pos = d3
    .arc<d3.PieArcDatum<PieData>>()
    .innerRadius(radius - 25 * radiusScaleFactor)
    .outerRadius(radius - 25 * radiusScaleFactor)

  g.append('path')
    .attr('d', arc)
    .style('fill', (d) => colour(d.data))

  g.append('text')
    .attr('transform', function (d) {
      return 'translate(' + pos.centroid(d) + ')'
    })
    .attr('dy', '0.5em')
    .attr('style', `font: ${20 * radiusScaleFactor}px sans-serif;`)
    .style('fill', (d) => {
      const c = colour(d.data)
      const newColour = brightnessByColor(c) ?? 0
      return newColour < 120 ? 'white' : 'black'
    })
    .style('text-anchor', 'middle')
    .text(function (d) {
      return units.value === 'percent'
        ? Math.round((d.data.value * 100) / valueSum) + '%'
        : d.data.value
    })
}
</script>
