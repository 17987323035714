<template>
  <div
    v-if="selectedTab"
    class="p-16 rounded-40px"
    :class="{
      'bg-tl-green-600 dark:bg-tl-green-100': itemColour === 'green',
      'bg-tl-lilla-600 dark:bg-tl-lilla-100': itemColour === 'lilla'
    }"
  >
    <div class="flex flex-row">
      <div class="flex flex-col w-2/3">
        <div
          class="flex flex-row text-sm"
          :class="{
            'text-black dark:text-tl-green-400': itemColour === 'green',
            'text-black dark:text-tl-lilla-400': itemColour === 'lilla'
          }"
        >
          {{ meetingText }}
        </div>
        <div class="flex flex-row text-black dark:text-white text-4xl font-medium mt-4">
          <span v-html="feedback[0]" />
        </div>
        <div class="flex flex-row mt-8">
          <div
            id="main-content"
            class="text-black text-opacity-50 dark:text-white overflow-hidden"
            :class="{
              'max-h-6': !toggleText,
              'max-h-auto': toggleText
            }"
          >
            <span>Topics : </span><span v-html="feedback[1]" />
          </div>
        </div>
        <!-- Read More/Hide Button -->
        <div class="flex flex-row mt-1">
          <div class="flex flex-col">
            <button
              id="toggle-btn"
              class="text-black text-opacity-50 dark:text-white font-semibold focus:outline-none underline"
              @click="toggleText = !toggleText"
            >
              {{ toggleText ? 'Hide' : 'Read More' }}
            </button>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-1/12"></div>
      <div class="flex flex-col w-3/12 text-black dark:text-white">
        <!--div class="flex flex-row">{{ createdDate }}</div-->
        <div class="flex flex-row pb-5" v-if="meetingType == 'Online Meeting'">
          Participants ({{ durationChartData.length }})
        </div>
        <div class="flex flex-row my-4">
          <div
            class="px-2.5 py-1.5 rounded-md text-black"
            :class="{
              'bg-tl-green-400': itemColour === 'green',
              'bg-tl-lilla-400': itemColour === 'lilla'
            }"
          >
            {{ meetingType }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row" v-if="meetingType == 'Online Meeting'">
      <div
        class="flex flex-col w-1/2 mt-10 p-5 lg:p-10 mr-2 rounded-20px"
        :class="{
          'bg-tl-green-500 dark:bg-tl-green-200': itemColour === 'green',
          'bg-tl-lilla-500 dark:bg-tl-lilla-200': itemColour === 'lilla'
        }"
      >
        <div class="flex flex-row text-black dark:text-white relative">
          <div
            class="px-5 py-2.5 border rounded-3xl text-2xl relative"
            :class="{
              'border-black dark:border-tl-green-400': itemColour === 'green',
              'border-black dark:border-tl-lilla-400': itemColour === 'lilla'
            }"
          >
            <span>Speaking Time</span>
            <div
              class="rounded-full cursor-pointer absolute -top-0 -right-6 border-2 border-black dark:border-white p-2 w-4 h-4 text-xs text-center font-bold flex items-center justify-center"
              @click="toggleInfoBox('speakingTime')"
            >
              i
            </div>
          </div>
          <div
            v-if="infoBoxVisible === 'speakingTime'"
            class="ml-4 absolute right-0 top-0 max-w-48 bg-tl-grey-200 text-white p-6 rounded-xl"
          >
            Percentage of speaking time per person during the meeting
          </div>
        </div>
        <div class="flex flex-row mt-4 justify-center" id="durPieChart">
          <PieChart
            class="h-36 sm:h-44 md:h-52 lg:h-64 xl:h-80 2xl:h-96"
            :data="durationChartData"
            :colours="LegendColours"
          />
        </div>
      </div>
      <div
        class="flex flex-col w-1/2 mt-10 p-5 lg:p-10 ml-2 rounded-20px"
        :class="{
          'bg-tl-green-500 dark:bg-tl-green-200': itemColour === 'green',
          'bg-tl-lilla-500 dark:bg-tl-lilla-200': itemColour === 'lilla'
        }"
      >
        <div class="flex flex-row text-black dark:text-white relative">
          <div
            class="px-5 py-2.5 border rounded-3xl text-2xl relative"
            :class="{
              'border-black dark:border-tl-green-400': itemColour === 'green',
              'border-black dark:border-tl-lilla-400': itemColour === 'lilla'
            }"
          >
            <span>Speaking Turn</span>
            <div
              class="rounded-full cursor-pointer absolute -top-0 -right-6 border-2 border-black dark:border-white p-2 w-4 h-4 text-xs text-center font-bold flex items-center justify-center"
              @click="toggleInfoBox('speakingTurn')"
            >
              i
            </div>
          </div>
          <div
            v-if="infoBoxVisible === 'speakingTurn'"
            class="ml-4 absolute right-0 top-0 max-w-48 bg-tl-grey-200 text-white p-6 rounded-xl"
          >
            Number of times each person have spoken during the meeting
          </div>
        </div>
        <div class="flex flex-row mt-4 justify-center" id="turnPieChart">
          <PieChart
            class="h-32 sm:h-44 md:h-52 lg:h-64 xl:h-80 2xl:h-96"
            :data="turnsChartData"
            :colours="LegendColours"
            units="absolute"
          />
        </div>
      </div>
    </div>
    <div
      class="flex flex-row mt-10 p-10 rounded-20px text-black dark:text-white"
      :class="{
        'bg-tl-green-500 dark:bg-tl-green-200': itemColour === 'green',
        'bg-tl-lilla-500 dark:bg-tl-lilla-200': itemColour === 'lilla'
      }"
    >
      <div class="flex flex-col w-screen">
        <div class="flex flex-row">
          <div
            class="px-5 py-2.5 border rounded-3xl text-2xl"
            :class="{
              'border-black dark:border-tl-green-400': itemColour === 'green',
              'border-black dark:border-tl-lilla-400': itemColour === 'lilla'
            }"
          >
            Action Items
          </div>
        </div>
        <div class="flex flex-row mt-4">
          <div
            id="action-item-content"
            class="text-lg overflow-hidden"
            :class="{ 'max-h-0': !actionItemToggleText, 'max-h-auto': actionItemToggleText }"
          >
            <span v-html="feedback[2]" />
          </div>
        </div>
        <!-- Read More/Hide Button -->
        <div class="flex flex-row mt-5">
          <p
            id="toggle-btn"
            class="font-semibold underline cursor-pointer"
            :class="{
              'border-black dark:text-tl-green-400': itemColour === 'green',
              'border-black dark:text-tl-lilla-400': itemColour === 'lilla'
            }"
            @click="actionItemToggleText = !actionItemToggleText"
          >
            {{ actionItemToggleText ? 'Hide' : 'Read More' }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, watch, toRefs, computed, type ComputedRef } from 'vue'
import { useTeamStore } from '@/composition/stores/teamStore'
import { useMeetingStore } from '@/composition/stores/meetingStore'
import PieChart from '@/components/charts/PieChart.vue'
import type { PieData } from '@/components/charts/PieChart.vue'
import { LegendColours, promptSectionsMeetings } from '@/constants'
import { reMapNames } from '@/utilities'

const teamStore = useTeamStore()
const meetingStore = useMeetingStore()

const toggleText = ref(false)
const actionItemToggleText = ref(false)
const props = defineProps<{ selectedTab: string }>()
const { selectedTab } = toRefs(props)

const infoBoxVisible = ref('')

//const roles = ['Student', 'Teacher', 'Guest', 'Unknown']

/* const studentNameMap2 = Object.fromEntries(
  roles
    .flatMap((role) =>
      Array.from({ length: 12 }, (_, i) => {
        const num = i + 1
        return [
          [`${role.charAt(0)}-${num}`, `${role} ${num}`],
          [`${role} ${num}`, `${role} ${num}`]
        ]
      })
    )
    .flat()
) */

const studentNameMap = computed(() => {
  const names = selectedMeeting.value?.speakinginfo.map((item) => item.speaker) ?? []
  return reMapNames(names)
})

function toggleInfoBox(type: string) {
  if (infoBoxVisible.value === type) {
    infoBoxVisible.value = ''
  } else {
    infoBoxVisible.value = type
  }
}

const feedback = computed(() => {
  const f: String[] = []
  const feedback = teamStore.getters.meetingFeedback.value
  feedback.forEach((item) => {
    if (item.filename === selectedTab.value) {
      for (var index in promptSectionsMeetings) {
        if (promptSectionsMeetings[index] == item.section) {
          f.push(item.filefeedback)
        }
      }
    }
  })
  return f
})

const itemColour = computed(() => (meetingType.value === 'Online Meeting' ? 'green' : 'lilla'))

const meetingText = computed(() => {
  return selectedTab.value
    .slice(0, selectedTab.value.indexOf('GRP'))
    .replaceAll('_', ' ')
    .toUpperCase()
})

const selectedMeeting = computed(() => {
  return meetingStore.getters.speakingInfo.value.find((item) => item.filename == selectedTab.value)
})

const durationChartData: ComputedRef<PieData[]> = computed(() => {
  return (
    selectedMeeting.value?.speakinginfo.map((item) => {
      return {
        name: studentNameMap.value[item.speaker],
        value: item.duration
      }
    }) ?? []
  )
})

const turnsChartData: ComputedRef<PieData[]> = computed(() => {
  return (
    selectedMeeting.value?.speakinginfo.map((item) => {
      return {
        name: studentNameMap.value[item.speaker],
        value: item.count
      }
    }) ?? []
  )
})

const meetingType = computed(() => {
  return selectedMeeting.value?.type === 'online' ? 'Online Meeting' : 'Physical Meeting'
})

watch(selectedTab, () => {
  actionItemToggleText.value = false
  toggleText.value = false
})
</script>

<style>
li.action-item {
  margin-bottom: 10px;
}
</style>
