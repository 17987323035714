<template>
  <div class="absolute top-16 left-0 flex flex-col b-white dark:bg-tl-grey-100 p-10 pt-0 w-full">
    <!-- Tabs -->
    <div class="flex flex-row p-2 pt-0 mt-2">
      <div
        v-for="tab of tabs"
        :key="tab.routeName"
        class="rounded-full p-2 px-3.5 mr-2 last:mr-0 text-sm font-semibold cursor-pointer border border-black"
        :class="activeClass(tab.routeName)"
        @click="routeTo(tab.routeName)"
      >
        {{ t(tab.label) }}
      </div>
    </div>
    <!-- Tab Content -->
    <router-view :time="state.currentTime" :trim="state.currentTrim" v-slot="{ Component }">
      <component :is="Component" :selectedTab="selectedTab">
        <template v-slot:header>
          <span v-if="selectedTab === TabName.TaskCoordination">
            {{ t('infoTC') }}
          </span>
          <span v-if="selectedTab === TabName.Communication">
            {{ t('infoC') }}
          </span>
        </template>
      </component>
    </router-view>
    <!-- Timeline -->
    <TimelineModule
      class="mt-2"
      :initialTime="state.currentTime"
      :initialTrim="state.initialTrim"
      :selectedTab="selectedTab"
      @trimupdate="(trim: number[]) => (state.currentTrim = trim)"
    ></TimelineModule>
  </div>
</template>

<script setup lang="ts">
import router from '@/router'
import { useI18n } from 'vue-i18n'
import TimelineModule from '@/components/nursing/TimelineModule.vue'
import { useSessionStore } from '@/composition/stores/sessionStore.js'
import { onMounted, reactive, ref } from 'vue'
import { Speaker, TabName } from '@/constants'

const tabs = [
  {
    label: 'General',
    routeName: TabName.General
  },
  {
    label: 'Close-loop Communication',
    routeName: TabName.Communication
  },
  {
    label: 'Task Coordination',
    routeName: TabName.TaskCoordination
  },
  {
    label: 'Stress Response',
    routeName: TabName.StressManagement
  }
]

const messages = {
  en: {
    General: 'General',
    'Close-loop Communication': 'Close-loop Communication',
    'Task Coordination': 'Task Coordination',
    'Stress Response': 'Stress Response',
    infoC:
      'from the simulation are shown below. Coloured circles indicate active roles and arrows represent closed loop communication between sender and receiver. Click an event for details.',
    infoTC:
      'from the simulation are shown below. Coloured circles indicate active roles and arrows represent task coordination between sender and receiver. Click an event for details.'
  },
  no: {
    General: 'Generelt',
    'Close-loop Communication': 'Closed-loop kommunikasjon',
    'Task Coordination': 'Rolle- og oppgavefordeling',
    'Stress Response': 'Stressrespons',
    infoC:
      'fra simuleringen vises nedenfor. Fargede sirkler indikerer aktive roller, og piler representerer closed-loop kommunikasjon mellom avsender og mottaker. Klikk på en hendelse for detaljer.',
    infoTC:
      'fra simuleringen vises nedenfor. Fargede sirkler indikerer aktive roller, og piler representerer oppgavekoordinering mellom avsender og mottaker. Klikk på en hendelse for detaljer.'
  }
}

const { t } = useI18n({ messages })

interface State {
  currentTime: number
  currentTrim: number[]
  initialTime: number
  initialTrim: number[]
}

const state: State = reactive({
  currentTime: 0,
  currentTrim: [0, 0],
  initialTime: 0,
  initialTrim: [0, 0]
})

const { getters: sessionGetters, actions: sessionActions } = useSessionStore()
const selectedTab = ref(TabName.General)

onMounted(async () => {
  let session = sessionGetters.selectedSession.value
  // Try to reload a previous group if none selected
  if (!session) {
    const groupName = sessionStorage.getItem('group')
    const sessionID = sessionStorage.getItem('session')
    await sessionActions.getGroupsForUser()
    const route = router.currentRoute.value
    const groups = sessionGetters.groups.value
    const preselectedGroup = groups.find((g) => g.name === groupName) ?? { name: '', sessions: [] }
    const preselectedSession = preselectedGroup.sessions.find((s) => s.id === sessionID)
    if (groupName && preselectedSession) {
      sessionActions.setSelectedSession(preselectedSession)
      await sessionActions.getDataCSV(groupName, preselectedSession.session ?? 1)
      routeTo(route.name as TabName)
    } else routeTo(TabName.General)
  }
})

function routeTo(tab: TabName) {
  selectedTab.value = tab
  sessionActions.setTab(tab)
  sessionActions.setSelectedEvent()
  if (tab === TabName.TaskCoordination || tab === TabName.Communication) {
    sessionActions.setSpeaker(Speaker.All)
  } else sessionActions.setSpeaker(Speaker.None)
  router.push({ name: tab })
}

const activeClass = (tabName: string) =>
  selectedTab.value === tabName ? 'bg-white text-tl-grey-100' : 'bg-tl-grey-200 text-white'
</script>
