/* This store is used to handle the data fetch from nettskjema */

import { computed, reactive, type ComputedRef } from 'vue'
import useApiService from '@/apiRequest.js'
import { ErrorCode, NETTSKJEMA_FORM_IDS, XHR_REQUEST_TYPE } from '@/constants.js'
import type { Answer, FormUser } from '@teams'
import useDialogStore from '@/composition/stores/dialogStore.js'

const dialogStore = useDialogStore()

export interface SurveyResponseData {
  studentsResponse: Record<string, unknown>
  teachersResponse: Record<string, unknown>
}

export interface Question {
  questionId: number
  questionText: string
  nettskjemaId: string
}

interface State {
  answers: Answer[]
  questions: Question[]
  users: FormUser[]
  nettskjemaIds: number[]
}

const state: State = reactive({
  answers: [],
  questions: [],
  users: [],
  nettskjemaIds: NETTSKJEMA_FORM_IDS
})
// ------------  Internal functions ------------

const apiService = useApiService()

// ------------  Getters --------------

// Once a reactive getter has been gotten by a component
// we cannot overwrite its instance here in the store - but we can write to its children reactively
// Complex objects provided by a getter here should be represented by a Class and also have an update() function
interface Getters {
  answers: ComputedRef<Answer[]>
  questions: ComputedRef<Question[]>
  users: ComputedRef<FormUser[]>
  nettskjemaIds: ComputedRef<number[]>
}

const getters = {
  get answers(): ComputedRef<Answer[]> {
    return computed(() => state.answers) // This is the 'currently selected' Team
  },
  get questions(): ComputedRef<Question[]> {
    return computed(() => state.questions) // This is the 'currently selected' Team
  },
  get users(): ComputedRef<FormUser[]> {
    return computed(() => state.users)
  },
  get nettskjemaIds(): ComputedRef<number[]> {
    return computed(() => state.nettskjemaIds)
  }
}

interface Actions {
  selectSurveyForm: (team: string | undefined) => Promise<void>
}
const actions = {
  selectSurveyForm: async function (team: string | undefined): Promise<void> {
    const team_id = team || 'no_group'
    const response = await apiService.apiRequest<{
      answers: Answer[]
      questions: Question[]
      users: FormUser[]
    }>({
      errorCode: ErrorCode.SURVEY,
      route: '/api/survey',
      method: XHR_REQUEST_TYPE.GET,
      query: { ids: state.nettskjemaIds.join(','), group: team_id }
    })
    if (response.error) {
      response.error.message = 'Unable to get group link'
      dialogStore.actions.handleError(response)
    } else if (response.data) {
      state.answers = response.data.answers
      state.questions = response.data.questions
      state.users = response.data.users
    }
  }
}

interface ServiceInterface {
  actions: Actions
  getters: Getters
}
// This defines the interface used externally
export function useSurveyStore(): ServiceInterface {
  return {
    getters,
    actions
  }
}

export type useSurveyStore = ReturnType<typeof useSurveyStore>
