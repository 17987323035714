import { type ComputedRef, computed, reactive } from 'vue'
import { type ExtendedStoreResponse, type StoreResponse } from '@/models/main.js'
import type { APIRequestPayload, DataCSVRow, NursingGroup, TLEvent } from '@frontend'
import { EventStatus, EventType, Speaker, TabName, XHR_CONTENT_TYPE } from '@/constants.js'
import type { Session } from '@database'
import useApiService from '@/apiRequest.js'
import type { Segment } from '@database'
import { ErrorCode, XHR_REQUEST_TYPE } from '@/constants.js'
import type { ModuleType } from '@models'
import { parseTLTime } from '@/utilities.js'
import useDialogStore from '@/composition/stores/dialogStore.js'

const dialogStore = useDialogStore()
const apiService = useApiService()

// TODO: define some generic class / interface for that
interface Visualisation {}
type EditableSegment = Segment & { index: number }

enum FileState {
  Default = 'Default',
  Processing = 'Processing',
  Done = 'Done'
}

// TODO: outsource ProcessingState to common type repo on the server end...
interface State {
  visualisations: Visualisation[] | undefined
  fileStates: Record<string, FileState> // map of visualisation ids and their state
  groups: NursingGroup[]
  selectedGroup: NursingGroup | undefined
  selectedSession: Session | undefined
  selectedSpeaker: Speaker
  selectedTab: TabName
  selectedEvent: TLEvent | undefined
  csvData: DataCSVRow[]
  keyEvents: TLEvent[]
  validSpeakers: Speaker[]
  events: {
    [key in TabName]: TLEvent[]
  }
}

const state: State = reactive({
  visualisations: undefined,
  fileStates: {},
  groups: [],
  selectedGroup: undefined,
  selectedSession: undefined,
  selectedTab: TabName.General,
  selectedSpeaker: Speaker.None,
  selectedEvent: undefined,
  csvData: [],
  keyEvents: [],
  validSpeakers: [],
  events: {
    [TabName.General]: [],
    [TabName.Communication]: [],
    [TabName.TaskCoordination]: [],
    [TabName.StressManagement]: []
  }
})

const groupOnReload = sessionStorage.getItem('group')
if (groupOnReload) state.selectedGroup = { name: groupOnReload, sessions: [] }

export interface Actions {
  setTab: (tab: TabName) => void
  setSpeaker: (student: Speaker) => void
  setSelectedGroup: (group: NursingGroup) => void
  setSelectedSession: (session: Session) => void
  setSelectedEvent: (event?: TLEvent) => void
  resetSelectedEvent: () => void
  getGroupsForUser: () => Promise<StoreResponse>
  getGroupLink(groupName: string): Promise<ExtendedStoreResponse<string>>
  insertSegment: (
    atIndex: number,
    replace: boolean,
    segment?: EditableSegment
  ) => EditableSegment | undefined

  // New routines 2nd prototype
  getDataCSV: (group: string, session: number) => Promise<void>
  setEvents: (key: TabName, eventList: TLEvent[]) => void
  createSession: (groupName: string) => Promise<StoreResponse>
  runPipeline: (sessionId: string, mode: string, modules?: ModuleType[]) => Promise<StoreResponse>
}

export interface Getters {
  groups: ComputedRef<NursingGroup[]>
  selectedGroup: ComputedRef<NursingGroup | undefined>
  selectedSession: ComputedRef<Session | undefined>
  selectedTab: ComputedRef<TabName>
  selectedSpeaker: ComputedRef<Speaker>
  selectedEvent: ComputedRef<TLEvent | undefined>
  visualisations: ComputedRef<Visualisation[] | undefined>
  fileState: ComputedRef<Record<string, FileState>>
  keyEvents: ComputedRef<TLEvent[]>
  validSpeakers: ComputedRef<Speaker[]>
  events: ComputedRef<TLEvent[]>
}

export interface ServiceInterface {
  state: State
  actions: Actions
  getters: Getters
}

const acceptableSpeakers = [
  Speaker.LeadNurse,
  Speaker.SupportNurse1,
  Speaker.SupportNurse2,
  Speaker.SupportNurse3,
  Speaker.AS,
  Speaker.S1,
  Speaker.S2,
  Speaker.S3
]

function useSessionStore(): ServiceInterface {
  // Process the CSV data file to create events that will be shown on the timeline
  function createEventsFromSession() {
    if (state.selectedSession && state.selectedSession.keyEvents['Simulation_Start']) {
      state.keyEvents = []
      const session = state.selectedSession
      const startTime = parseTLTime(session.keyEvents['Simulation_Start'])
      delete session.keyEvents['Baseline_Start']
      delete session.keyEvents['Baseline_End']
      delete session.keyEvents['Headset_Start']
      delete session.keyEvents['Headset_End']

      const keys = Object.keys(session.keyEvents)
      keys.forEach((key, index) => {
        const event: TLEvent = {
          id: `keyevent-${index}`,
          oid: '',
          type: EventType.Key,
          name: key.replace('_', ' '),
          start: parseTLTime(session.keyEvents[key]) - startTime,
          end: -1,
          description: '',
          owner: Speaker.None,
          status: EventStatus.None,
          codes: []
        }
        state.keyEvents.push(event)
      })
    }
  }

  // Process the CSV data file to create events that will be shown on the timeline
  function createEventsFromCSV() {
    const loopMap: Map<string, TLEvent> = new Map()
    const taskMap: Map<string, TLEvent> = new Map()
    let loopIndex = 1
    let taskIndex = 1
    state.csvData.forEach((row, index) => {
      const loopID = row['Loop ID'] && parseInt(row['Loop ID'], 10).toString() // remove leading 0's by parsing to int first
      const taskID = row['Task ID'] && parseInt(row['Task ID'], 10).toString()
      if (loopID) {
        if (loopMap.has(loopID)) {
          const event = loopMap.get(loopID)!
          event.end = parseTLTime(row.End)
          event.codes.push({
            speaker: row.Speaker,
            receiver: row.Receiver,
            utterance: row.Utterance,
            code: row['Loop Code']
          })
          event.endRow = index
        } else {
          const event: TLEvent = {
            id: `loopevent-${index}`,
            oid: loopID,
            type: EventType.Loop,
            name: `${loopIndex}`,
            start: parseTLTime(row.Start),
            end: parseTLTime(row.End),
            status: row['Loop Status'],
            owner: row['Speaker'] as Speaker,
            description: row['Loop Description'],
            codes: [
              {
                speaker: row.Speaker,
                receiver: row.Receiver,
                utterance: row.Utterance,
                code: row['Loop Code']
              }
            ],
            startRow: index
          }
          loopMap.set(loopID, event)
          loopIndex++
        }
      } else if (taskID) {
        if (taskMap.has(taskID)) {
          const event = taskMap.get(taskID)!
          event.end = parseTLTime(row.End)
          event.codes.push({
            speaker: row.Speaker,
            receiver: row.Receiver,
            utterance: row.Utterance,
            code: row['Task Code']
          })
          event.endRow = index
        } else {
          const event: TLEvent = {
            id: `taskevent-${index}`,
            oid: taskID,
            type: EventType.Task,
            name: `${taskIndex}`,
            start: parseTLTime(row.Start),
            end: parseTLTime(row.End),
            status: row['Task Status'],
            owner: row['Speaker'] as Speaker,
            description: row['Task Description'],
            codes: [
              {
                speaker: row.Speaker,
                receiver: row.Receiver,
                utterance: row.Utterance,
                code: row['Task Code']
              }
            ],
            startRow: index
          }
          taskMap.set(taskID, event)
          taskIndex++
        }
      }
      if (
        row.Speaker &&
        acceptableSpeakers.includes(row.Speaker as Speaker) &&
        !state.validSpeakers.includes(row.Speaker as Speaker)
      )
        state.validSpeakers.push(row.Speaker as Speaker)
    })
    state.events[TabName.Communication] = Array.from(loopMap.values())
    state.events[TabName.TaskCoordination] = Array.from(taskMap.values())
  }

  const actions = {
    setSelectedGroup(group: NursingGroup): void {
      state.selectedGroup = group
      sessionStorage.setItem('group', group.name)
    },

    setSelectedSession(session: Session): void {
      state.selectedSession = session
      sessionStorage.setItem('session', session.id)
      createEventsFromSession()
    },

    setSelectedEvent(event?: TLEvent): void {
      state.selectedEvent = event
    },

    resetSelectedEvent(): void {
      state.selectedEvent = undefined
    },

    async createSession(groupName: string): Promise<StoreResponse> {
      const res = await apiService.apiRequest<Response>({
        errorCode: ErrorCode.NURSING,
        route: `/api/pipeline/${groupName}`,
        method: XHR_REQUEST_TYPE.POST,
        credentials: true,
        contentType: XHR_CONTENT_TYPE.JSON
      })
      if (res.error) {
        const error = 'Unable to create new session'
        console.warn(res.error ? res.error : error)
      } else {
        return { success: true }
      }
      return { success: false, error: res.error }
    },

    async getGroupLink(groupName: string): Promise<ExtendedStoreResponse<string>> {
      const payload: APIRequestPayload = {
        errorCode: ErrorCode.NURSING,
        method: XHR_REQUEST_TYPE.GET,
        contentType: XHR_CONTENT_TYPE.JSON,
        credentials: true,
        route: `/api/group/link?group=${groupName}`,
        convertDates: false
      }
      const res = await apiService.apiRequest<{ code: string }>(payload)
      if (res.error) {
        res.error.message = 'Unable to get group link'
        dialogStore.actions.handleError(res)
      } else if (res.data) {
        return { success: true, data: res.data.code as string }
      }
      return { success: false, error: res.error, data: '' }
    },

    async runPipeline(
      sessionId: string,
      mode: string = 'sync',
      modules?: ModuleType[]
    ): Promise<StoreResponse> {
      const res = await apiService.apiRequest<Response>({
        errorCode: ErrorCode.NURSING,
        route: `/api/pipeline/${sessionId}/${mode}`,
        method: XHR_REQUEST_TYPE.PUT,
        credentials: true,
        contentType: XHR_CONTENT_TYPE.JSON,
        body: {
          modules
        }
      })
      if (res.error) {
        res.error.message = 'Error during pipeline run'
        dialogStore.actions.handleError(res)
      } else {
        return { success: true }
      }
      return { success: false, error: res.error }
    },

    async getGroupsForUser(): Promise<StoreResponse> {
      const res = await apiService.apiRequest<NursingGroup[]>({
        errorCode: ErrorCode.LOGIN,
        route: `/api/groups`,
        method: XHR_REQUEST_TYPE.GET,
        credentials: true,
        contentType: XHR_CONTENT_TYPE.JSON
      })
      if (res.error) {
        res.error.message = 'Groups query contains no records or invalid session'
        dialogStore.actions.handleError(res)
      } else if (res.data) {
        const rows = Object.values(res.data)
        state.groups = rows
        return { success: true }
      }
      return { success: false, error: res.error }
    },

    // Inserts at given index and returns the index of the inserted segment
    // If replace == true, delete the existing segment before inserting (can be used to update a segment)
    // If segment is undefined and replace == true, just delete the given index
    insertSegment(
      atIndex: number,
      replace = false,
      segment?: EditableSegment
    ): EditableSegment | undefined {
      if (state.selectedSession) {
        if (segment) {
          state.selectedSession.segments.splice(atIndex, replace ? 1 : 0, segment)
          segment.index = atIndex
          return segment
        } else {
          state.selectedSession.segments.splice(atIndex, 1)
        }
      }
    },

    getDataCSV: async function (group: string, session: number): Promise<void> {
      const payload: APIRequestPayload = {
        errorCode: ErrorCode.NURSING,
        method: XHR_REQUEST_TYPE.GET,
        credentials: true,
        route: `/api/file?group=${group}&session=${session}&module=Transcript&index=0`,
        convertDates: false
      }
      const res = await apiService.apiRequest<DataCSVRow[]>(payload)
      if (res.error) {
        res.error.message = 'Unable to get data CSV'
        dialogStore.actions.handleError(res)
      } else if (res.data) {
        state.csvData = res.data
        createEventsFromCSV()
      }
    },
    setEvents: function (key: TabName, eventList: TLEvent[]): void {
      state.events[key] = eventList
    },
    setTab: function (tab: TabName): void {
      state.selectedTab = tab
    },
    setSpeaker: function (student: Speaker): void {
      state.selectedSpeaker = student
    }
  }

  const getters = {
    get groups(): ComputedRef<NursingGroup[]> {
      return computed(() => state.groups)
    },
    get selectedGroup(): ComputedRef<NursingGroup | undefined> {
      return computed(() => state.selectedGroup)
    },
    get selectedSession(): ComputedRef<Session | undefined> {
      return computed(() => state.selectedSession)
    },
    get selectedTab(): ComputedRef<TabName> {
      return computed(() => state.selectedTab)
    },
    get selectedSpeaker(): ComputedRef<Speaker> {
      return computed(() => state.selectedSpeaker)
    },
    get selectedEvent(): ComputedRef<TLEvent | undefined> {
      return computed(() => state.selectedEvent)
    },
    get visualisations(): ComputedRef<Visualisation[] | undefined> {
      return computed(() => state.visualisations)
    },
    get fileState(): ComputedRef<Record<string, FileState>> {
      return computed(() => state.fileStates)
    },
    get events(): ComputedRef<TLEvent[]> {
      return computed(() => state.events[state.selectedTab])
    },
    get keyEvents(): ComputedRef<TLEvent[]> {
      return computed(() => state.keyEvents)
    },
    get validSpeakers(): ComputedRef<Speaker[]> {
      return computed(() => state.validSpeakers)
    }
  }

  return { state, actions, getters }
}

export { useSessionStore }
