<template>
  <div
    v-if="state.message && state.message.type"
    class="fadein absolute top-5 right-5 z-10 w-64 h-16 p-1 bg-green-800 flex justify-center flex-col items-center rounded-md pointer-events-none"
    :class="{
      'bg-red-800 text-white': state.message.type == DialogMessageType.Error,
      'bg-white text-black': state.message.type == DialogMessageType.Information,
      'bg-green-800 text-white': state.message.type == DialogMessageType.Success,
      'bg-yellow-800 text-white': state.message.type == DialogMessageType.Warning
    }"
  >
    <p v-if="state.message.type === DialogMessageType.Error" class="text-md font-bold">
      {{ state.message.title }}
    </p>
    <p class="text-sm p-1">{{ state.message.message }}</p>
  </div>
</template>

<script setup lang="ts">
import { reactive, watch } from 'vue'
import { DialogMessageType, type DialogMessage } from '@/interfaces.js'
import useDialogStore from '@/composition/stores/dialogStore.js'

const dialogStore = useDialogStore()

const state = reactive({
  message: {} as DialogMessage | null,
  isProcessing: false,
  isEmptied: false
})

watch(dialogStore.state, (storeState) => {
  if (!state.isEmptied) {
    if (!state.isProcessing) {
      const message = dialogStore.getters.currentMessage()
      if (message) handleIncomingMessages(message)
    } else {
      // multiple messages at once
      let buffer = storeState.messages
      for (let message of buffer) {
        setTimeout(() => {
          if (message) handleIncomingMessages(message)
        }, message.duration)
      }
      dialogStore.actions.empty()
      state.isEmptied = true // remove all processed messages
      setTimeout(() => {
        state.isEmptied = false
      }, 2000)
    }
  }
})

function handleIncomingMessages(message: DialogMessage) {
  const currentMessage = message
  if (currentMessage) {
    // dialogStore has messages
    state.message = currentMessage
    state.isProcessing = true
    // NOTE: this is unfortunately necessary because the state would not update correctly otherwise
    setTimeout(() => {
      state.message = null
      state.isProcessing = false
    }, currentMessage.duration)
    if (message.callback) message.callback()
  }
}
</script>
