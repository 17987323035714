import { serverBaseUrl, HttpException, XHR_CONTENT_TYPE, XHR_REQUEST_TYPE } from './constants.js'

import type { APIRequestPayload, APIResponse } from '@/models/main.js'
import { parseWithDate } from '@/utilities.js'

interface ServiceInterface {
  apiRequest: <T>(
    {
      route,
      method,
      query, // A dictionary containing values to convert to a URL query string (e.g. ?a=yyy&b=zzz)
      body, // Can be FormData, JSON, text, or Object (unknown type). Use contentType to specify which
      headers,
      credentials,
      contentType, // Chosen using XHR_CONTENT_TYPE enumerator. Defaults to 'application/json'
      baseURL
    }: APIRequestPayload,
    callback?: () => void
  ) => Promise<APIResponse<T>>
}

function getEncodedParams(query: Record<string, string>): string {
  // Encode query and body
  let qs = ''
  const queryKeys = query ? Reflect.ownKeys(query).map((k) => k.toString()) : []
  if (query && queryKeys.length > 0) {
    qs += '?'
    queryKeys.forEach((key, index) => {
      qs += `${key}=${query[key]}`
      qs += index < queryKeys.length - 1 ? '&' : ''
    })
  }
  return encodeURI(qs)
}

function useApiService(): ServiceInterface {
  return {
    /**
     * apiRequest
     * prepares a xhrhttprequest according to the given parameters
     * @param param0
     * @returns
     */
    apiRequest: <T>(
      {
        errorCode,
        route,
        method,
        query, // A dictionary containing values to convert to a URL query string (e.g. ?a=yyy&b=zzz)
        body = {}, // Can be FormData, JSON, text, or Object (unknown type). Use contentType to specify which
        headers,
        credentials = true,
        contentType, // Chosen using XHR_CONTENT_TYPE enumerator. Defaults to 'application/json'
        baseURL = '',
        convertDates = false
      }: APIRequestPayload,
      callback?: () => void
    ): Promise<APIResponse<T>> => {
      // Set token if available
      const ct = contentType || XHR_CONTENT_TYPE.JSON
      const _baseUrl = baseURL !== undefined ? baseURL : serverBaseUrl

      // Set headers
      headers = {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': ct,
        ...headers
      }

      let data: XMLHttpRequestBodyInit

      if (ct !== (XHR_CONTENT_TYPE.MULTIPART as string)) {
        // Convert body to correct format based on contentType
        if (typeof body === 'string' && ct === (XHR_CONTENT_TYPE.URLENCODED as string)) {
          data = body
        } else {
          data = JSON.stringify(body)
        }
      } else {
        data = body as FormData
        delete headers['Content-Type'] // Adding an explicit content type causes problems with Multer. Allow the browser to set it.
      }

      return new Promise<APIResponse<T>>((resolve) => {
        const xhr = new XMLHttpRequest()

        // Event listener must be added before calling open()
        xhr.addEventListener('loadend', () => {
          const res: APIResponse<T> = {
            status: xhr.status,
            dataType: XHR_CONTENT_TYPE.JSON,
            data: undefined,
            callback
          }

          const contentType = xhr.getResponseHeader('content-type') || ''
          if (contentType && contentType.includes('application/json')) {
            res.data = convertDates ? parseWithDate(xhr.response) : JSON.parse(xhr.response)
          } else {
            res.dataType = XHR_CONTENT_TYPE.TEXT
            res.data = xhr.response
          }

          // Account for server-side errors
          if (xhr.status >= 400) {
            res.error = new HttpException(
              xhr.status,
              `HTTP error: ${xhr.status} : ${xhr.statusText} : error code: ${errorCode}`
            )
          }
          resolve(res)
        })

        // Account for unexpected / network errors
        xhr.addEventListener('error', (error: ProgressEvent) => {
          const res: APIResponse<T> = {
            status: xhr.status,
            dataType: XHR_CONTENT_TYPE.TEXT,
            data: undefined,
            error: new HttpException(
              xhr.status,
              `Progress error: ${error.type} : error code: ${errorCode}`
            ),
            callback
          }
          resolve(res)
        })

        let encodedQueries = ''
        if (query !== undefined) {
          // query is an optional parameter
          encodedQueries = getEncodedParams(query)
        }

        const url = `${_baseUrl}${route}${encodedQueries}`

        xhr.open(method, url)
        xhr.withCredentials = credentials

        if (headers !== undefined) {
          // headers is an optional argument
          for (const key of Object.keys(headers)) {
            xhr.setRequestHeader(key, headers[key])
          }
        }

        try {
          if (method === XHR_REQUEST_TYPE.GET) {
            xhr.send()
          } else {
            xhr.send(data)
          }
        } catch (error: unknown) {
          console.error(error)
        }
      })
    }
  }
}

export default useApiService
