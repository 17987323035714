<template>
  <div
    class="relative flex flex-row justify-center min-h-screen"
    :class="{
      'dark bg-tl-grey-100': darkMode,
      'bg-white': !darkMode
    }"
  >
    <div class="w-full max-w-[1600px] p-4 flex flex-col">
      <MenuBar />
      <transition name="fade" mode="out-in">
        <component :is="comp" :key="$route.path" />
      </transition>
      <DialogManager />
      <DialogBox v-if="dialogConfig && dialogConfig.visible" />
      <router-view />
    </div>
  </div>
</template>

<script setup lang="ts">
import { type Component } from 'vue'

import { useAppStore } from '@/composition/stores/appStore.js'

import DialogManager from '@/components/dialog/DialogManager.vue'
import DialogBox from '@/components/dialog/DialogBox.vue'
import MenuBar from './views/MenuBar.vue'

const appStore = useAppStore()
const dialogConfig = appStore.getters.dialogConfig
const darkMode = appStore.getters.darkMode
let comp: Component
</script>

<style>
/* ---- Transition effects available throughout the app ---- */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
