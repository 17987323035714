<template>
  <div class="relative px-5 bg-tl-grey-800 dark:bg-tl-grey-100 rounded-md mb-4 flex flex-col pt-6">
    <div class="-top-12 right-0 flex flex-row justify-between cursor-pointer">
      <div class="flex items-center mr-10">
        <div
          class="rounded-l-md p-1 border border-black border-r-2 border-r-white dark:border-r-black"
          :class="[
            state.viewMode === ViewMode.group ? 'bg-white text-black' : 'bg-tl-grey-200  text-white'
          ]"
          @click="selectMode('group')"
        >
          {{ t('Group') }}
        </div>
        <div
          class="rounded-r-md p-1 pr-2 border border-black border-r-1 border-l-white dark:border-l-black"
          :class="[
            state.viewMode === ViewMode.individual
              ? 'bg-white text-black'
              : 'bg-tl-grey-200  text-white'
          ]"
          @click="selectMode('individual')"
        >
          {{ t('Individual') }}
        </div>
      </div>
      <div v-if="state.viewMode === ViewMode.individual" class="flex">
        <div
          v-for="(student, index) in state.heartData.map((d) => d.student as Speaker)"
          :key="index"
          class="rounded-full w-22 mx-2 p-2 border border-black h-10 flex items-center"
          :class="[
            selectedSpeaker === student ? 'bg-white text-black' : 'bg-tl-grey-200  text-white'
          ]"
          @click="setSpeaker(student)"
        >
          <p>{{ t(student.toLowerCase() + '_fullname') }}</p>
        </div>
      </div>
      <div class="flex flex-col text-xs">
        <div class="flex flex-row items-center">
          <svg width="40" height="10" xmlns="http://www.w3.org/2000/svg" class="mr-2">
            <path stroke="#0511F2" d="M0,5 l40,0" />
          </svg>
          <p class="text-black dark:text-white">{{ t('Heart Rate') }}</p>
        </div>
        <div class="flex flex-row items-center">
          <svg width="40" height="10" xmlns="http://www.w3.org/2000/svg" class="mr-2">
            <g fill="none" stroke="#F22929" stroke-width="1">
              <path stroke-dasharray="10,5" d="M0,5 l40,0" />
            </g>
          </svg>
          <p class="text-black dark:text-white">{{ t('Arousal Threshold') }}</p>
        </div>
        <div class="flex flex-row items-center">
          <svg width="40" height="10" xmlns="http://www.w3.org/2000/svg" class="mr-2">
            <rect x="0" width="40" height="10" opacity="0.2" fill="yellow" />
          </svg>
          <p class="text-black dark:text-white">{{ t('Calling Doctor') }}</p>
        </div>
      </div>
    </div>
    <div
      v-if="state.viewMode === ViewMode.group"
      class="grid grid-cols-2 gap-4 flex-shrink p-2 w-full bg-tl-grey-800 dark:bg-tl-grey-100"
    >
      <template v-for="chartData in state.heartData" :key="chartData.student">
        <div class="flex flex-row items-center min-w-[50%]">
          <div
            class="flex flex-row justify-center items-center rounded-md w-8 h-56 mb-6 mr-2"
            :class="{
              'bg-tl-student-ln': chartData.student == Speaker.LeadNurse,
              'bg-tl-student-sn1': chartData.student == Speaker.SupportNurse1,
              'bg-tl-student-sn2': chartData.student == Speaker.SupportNurse2,
              'bg-tl-student-sn3': chartData.student == Speaker.SupportNurse3
            }"
          >
            <p class="-rotate-90 text-white w-48 text-nowrap">
              {{ t(chartData.student.toLowerCase()) }}
            </p>
          </div>
          <TimelineChart
            class="h-72 w-full"
            :time="state.currentTime"
            :events="eventOverlays"
            :line="chartData"
            :darkmode="darkMode"
          ></TimelineChart>
        </div>
      </template>
    </div>
    <div v-else class="flex flex-row m-2 w-full">
      <div class="flex flex-col mr-2 min-w-[60%] flex-grow flex-shrink">
        <div class="w-full"></div>
        <template v-for="chartData in state.heartData" :key="`chart-heart-${chartData.student}`">
          <TimelineChart
            v-if="selectedSpeaker === chartData.student"
            class="h-1/2"
            :time="state.currentTime"
            :events="eventOverlays"
            :line="chartData"
            :darkmode="darkMode"
          ></TimelineChart>
        </template>
        <template
          v-for="chartData in state.accelerationData"
          :key="`chart-acc-${chartData.student}`"
        >
          <TimelineChart
            v-if="selectedSpeaker === chartData.student"
            class="h-1/2 mt-2"
            :time="state.currentTime"
            :events="[]"
            :line="chartData"
            :darkmode="darkMode"
          ></TimelineChart>
        </template>
      </div>
      <VideoModule
        class="w-[40%]"
        :session="sessionGetters.selectedSession.value?.session ?? 1"
        :group="sessionGetters.selectedGroup.value?.name ?? 'group1'"
        @timeupdate="(time: number) => (state.currentTime = time)"
      ></VideoModule>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, reactive, watch, type ComputedRef } from 'vue'
import { useI18n } from 'vue-i18n'
import { useSessionStore } from '@/composition/stores/sessionStore.js'
import { useAppStore } from '@/composition/stores/appStore.js'
import useApiService from '@/apiRequest.js'
import VideoModule from '@/components/nursing/VideoModule.vue'
import {
  TabName,
  EventType,
  EventStatus,
  Speaker,
  columnDefs,
  ErrorCode,
  XHR_REQUEST_TYPE
} from '@/constants'
import type { EventMarker, ChartData } from '@/components/charts/TimelineChart.vue'
import TimelineChart from '@/components/charts/TimelineChart.vue'
import type { TLEvent } from '@/models/nursing'
import type { APIRequestPayload } from '@/models'
import useDialogStore from '@/composition/stores/dialogStore.js'

enum ViewMode {
  group = 'group',
  individual = 'individual'
}

type ColumnNames = 'Time' | 'LN' | 'SN1' | 'SN2' | 'SN3' | 'S5' | 'S6'

type ThresholdColumns = 'Speaker' | 'Threshold'

interface State {
  currentTime: number
  accelerationData: ChartData[]
  heartData: ChartData[]
  viewMode: ViewMode
  checkedNames: string[]
}

const { getters: sessionGetters, actions: sessionActions } = useSessionStore()
const { getters: appGetters } = useAppStore()
const dialogStore = useDialogStore()
const apiService = useApiService()
const session = sessionGetters.selectedSession
const darkMode = appGetters.darkMode

let heartData: Record<ColumnNames, number>[]
let accelerationData: Record<ColumnNames, number>[]

const thresholds: Record<Speaker, number> = {
  [Speaker.LeadNurse]: 0,
  [Speaker.SupportNurse1]: 0,
  [Speaker.SupportNurse2]: 0,
  [Speaker.SupportNurse3]: 0,
  [Speaker.AS]: 0,
  [Speaker.S1]: 0,
  [Speaker.S2]: 0,
  [Speaker.S3]: 0,
  [Speaker.S5]: 0,
  [Speaker.S6]: 0,
  [Speaker.None]: 0,
  [Speaker.Patient]: 0,
  [Speaker.All]: 0
}

const messages = {
  en: {
    handoverEnd: 'Handover End',
    patientCrisis: 'Patient Crisis',
    Group: 'Group',
    Individual: 'Individual',
    'Time (min)': 'Time (min)',
    'Acceleration (Sudden movement)': 'Acceleration (Sudden movement)',
    'Heart Rate': 'Heart Rate',
    'Heart Rate (bpm)': 'Heart Rate (bpm)',
    'Arousal Threshold': 'Arousal Threshold',
    'Doctor Calling': 'Calling Doctor'
  },
  no: {
    handoverEnd: 'Rapportslutt',
    patientCrisis: 'Pasientforverring',
    Group: 'Gruppe',
    Individual: 'Individuell​',
    'Time (min)': 'Tid (minutter)​',
    'Acceleration (Sudden movement)': 'Plutselig bevegelse​',
    'Heart Rate': 'Puls',
    'Heart Rate (bpm)': 'Puls (bpm)​',
    'Arousal Threshold': 'Hvilepuls​',
    'Doctor Calling': 'Ringe Lege'
  }
}
const { t } = useI18n({ messages })

const state: State = reactive({
  currentTime: 0,
  accelerationData: [],
  heartData: [],
  viewMode: ViewMode.group,
  checkedNames: []
})

const selectedSpeaker = sessionGetters.selectedSpeaker
const selectedEvent = sessionGetters.selectedEvent
const languageCode = appGetters.languageCode

let callStart: TLEvent | undefined
let callEnd: TLEvent | undefined
let handoverEnd: TLEvent | undefined
let patientCrisis: TLEvent | undefined

const heartMap = new Map<string, ChartData>()
const accMap = new Map<string, ChartData>()

watch(
  () => session.value,
  async () => {
    await callData()
    if (heartData && accelerationData) setupChart()
    setupEvents()
  }
)

watch(
  () => languageCode.value,
  () => {
    nextTick(() => {
      if (heartData && accelerationData) setupChart()
      setupEvents()
    })
  }
)

onMounted(async () => {
  await callData()
  if (heartData && accelerationData) setupChart()
  setupEvents()
})

function selectMode(name: string) {
  if (name === 'group') {
    state.viewMode = ViewMode.group
    sessionActions.setSpeaker(Speaker.None)
  } else if (name === 'individual') {
    state.viewMode = ViewMode.individual
    sessionActions.setSpeaker(Speaker.LeadNurse)
  }
}

function setupEvents() {
  const iter = heartMap.values()
  const events: TLEvent[] = []
  let currentEvent: TLEvent | undefined

  let result = iter.next()
  while (!result.done) {
    let index = 1
    let samples = 0
    const data = result.value.data
    const owner = result.value.student
    const threshold = thresholds[owner]
    data.forEach((lineData, index2) => {
      if (lineData.y > threshold) {
        samples++
        if (currentEvent) currentEvent.end = lineData.x
        else {
          currentEvent = {
            id: `event-${result.value?.student}-${index}-${index2}`,
            oid: '',
            type: EventType.Stress,
            name: `${index}`,
            status: EventStatus.None,
            owner,
            description: '',
            codes: [],
            start: lineData.x,
            end: lineData.x
          }
        }
      } else if (currentEvent && samples > 2) {
        events.push(currentEvent)
        currentEvent = undefined
        samples = 0
        index++
      } else {
        currentEvent = undefined
        samples = 0
      }
    })
    result = iter.next()
  }
  sessionActions.setEvents(TabName.StressManagement, events)
}

function setSpeaker(speaker: Speaker) {
  sessionActions.setSpeaker(speaker)
}

const eventOverlays: ComputedRef<EventMarker[]> = computed(() => {
  const events: EventMarker[] = []
  // Events auto-detected using the thresholds for each student
  // Display only the event currently selected in the timeline
  const evs = sessionGetters.events.value
    .filter((e) => e.owner == selectedSpeaker.value)
    .filter((e) => e.id == selectedEvent.value?.id)
  evs.forEach((e, i) =>
    events.push({
      key: `event-marker-key-${i}`,
      start: e.start,
      end: e.end ?? -1,
      text: e.name,
      colour: 'red'
    })
  )
  // Doctor calling is a special event that always needs to be shown
  // This event is constructed from two marker times in the log file (key events)
  if (callStart && callStart.start > -1 && callEnd && callEnd.start && callEnd.start > -1) {
    events.push({
      key: `doctor-calling-marker-key`,
      start: callStart.start,
      end: callEnd.start,
      text: t('Doctor Calling'),
      colour: 'yellow'
    })
  }
  return events
})

async function callData() {
  if (session.value) {
    const s = session.value

    const payload: APIRequestPayload = {
      errorCode: ErrorCode.NURSING,
      method: XHR_REQUEST_TYPE.GET,
      credentials: true,
      route: `/api/file?group=${s.group}&session=${s.session}&module=StressManagement&index=0`,
      convertDates: false
    }

    let res = await apiService.apiRequest<Record<string, string>[]>(payload)
    if (res.error) {
      res.error.message = 'Unable to get heart rate data'
      dialogStore.actions.handleError(res)
    }
    if (res.data) heartData = res.data.map((d) => parseRow(d))

    payload.route = `/api/file?group=${s.group}&session=${s.session}&module=StressManagement&index=1`
    res = await apiService.apiRequest<Record<string, string>[]>(payload)
    if (res.error) {
      res.error.message = 'Unable to get acceleration data'
      dialogStore.actions.handleError(res)
    }
    if (res.data) accelerationData = res.data.map((d) => parseRow(d))

    payload.route = `/api/file?group=${s.group}&session=${s.session}&module=StressManagement&index=2`
    res = await apiService.apiRequest<Record<ThresholdColumns, string>[]>(payload)
    if (res.error) {
      res.error.message = 'Unable to get threshold data'
      dialogStore.actions.handleError(res)
    }
    if (res.data) {
      for (const d of res.data) {
        const key = translateSpeaker(d['Speaker'])
        thresholds[key] = parseFloat(d['Threshold'])
      }
    }
  }
}

function parseRow(d: Record<string, string>) {
  let newRow: Record<ColumnNames, number> = {
    Time: parseInt(d['Time']),
    LN: parseFloat(d['LN']),
    SN1: parseFloat(d['SN1']),
    SN2: parseFloat(d['SN2']),
    SN3: parseFloat(d['SN3']),
    S5: parseFloat(d['S5']),
    S6: parseFloat(d['S6'])
  }
  return newRow
}

function translateSpeaker(speaker: string) {
  switch (speaker) {
    case 'LN':
      return Speaker.LeadNurse
    case 'SN1':
      return Speaker.SupportNurse1
    case 'SN2':
      return Speaker.SupportNurse2
    case 'SN3':
      return Speaker.SupportNurse3
    case '4':
      return Speaker.S5
    case '5':
      return Speaker.S6
    default:
      return Speaker.None
  }
}

function setupChart() {
  callStart = sessionGetters.keyEvents.value.find((ke) => ke.name == 'Calling Start')
  callEnd = sessionGetters.keyEvents.value.find((ke) => ke.name == 'Calling End')
  handoverEnd = sessionGetters.keyEvents.value.find((ke) => ke.name == 'Handover End')
  patientCrisis = sessionGetters.keyEvents.value.find((ke) => ke.name == 'Patient Crisis')
  if (session.value) {
    state.accelerationData = []
    state.heartData = []

    heartMap.clear()
    accMap.clear()

    heartData.forEach((row) => {
      for (const entry of columnDefs) {
        const y = row[entry.name as ColumnNames]
        if (!y) continue

        // Initial value
        let sItem: ChartData = {
          key: entry.name,
          student: entry.name.slice(0, 3).trim() as Speaker,
          colour: entry.colour,
          yBounds: [0, 0],
          labels: ['x', 'y'],
          lines: [],
          data: []
        }

        if (heartMap.has(entry.name)) sItem = heartMap.get(entry.name)!
        else heartMap.set(entry.name, sItem)
        sItem.data.push({
          x: row.Time,
          y: isNaN(y) ? 0 : y
        })
      }
    })

    accelerationData.forEach((row) => {
      for (const entry of columnDefs) {
        const y = row[entry.name as ColumnNames]
        if (!y) continue

        // Initial value
        let sItem: ChartData = {
          key: entry.name,
          student: entry.name.slice(0, 3).trim() as Speaker,
          colour: entry.colour,
          yBounds: [0, 0],
          labels: ['x', 'y'],
          lines: [],
          data: []
        }

        if (accMap.has(entry.name)) sItem = accMap.get(entry.name)!
        else accMap.set(entry.name, sItem)
        sItem.data.push({
          x: row.Time,
          y: isNaN(y) ? 0 : y
        })
      }
    })

    // Set one-time attributes and add the data to the 'state'
    columnDefs.forEach((entry) => {
      const accItem = accMap.get(entry.name)
      if (accItem) {
        accItem.yBounds = [600, 1800]
        accItem.labels = [t('Time (min)'), t('Acceleration (Sudden movement)')]
        state.accelerationData.push(accItem)
      }
      const heartItem = heartMap.get(entry.name)
      if (heartItem) {
        heartItem.yBounds = [60, 130]
        heartItem.labels = [t('Time (min)'), t('Heart Rate (bpm)')]
        heartItem.lines.push({
          axis: 'y',
          value: thresholds[heartItem.student],
          label: '',
          colours: '#F22929,#F22929'
        })
        if (handoverEnd?.start) {
          heartItem.lines.push({
            axis: 'x',
            value: handoverEnd.start,
            label: t('handoverEnd'),
            colours: '#158B15,#158B15'
          })
        }
        if (patientCrisis?.start) {
          heartItem.lines.push({
            axis: 'x',
            value: patientCrisis.start,
            label: t('patientCrisis'),
            colours: '#158B15,#158B15'
          })
        }
        sessionGetters.keyEvents.value.filter((ke) => ke.name)
        state.heartData.push(heartItem)
      }
    })
  }
}
</script>
