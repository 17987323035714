<template>
  <div
    id="menubar"
    class="h-14 w-full flex items-center px-3 justify-start text-slate-white fadein"
    @click="selectLanguage = false"
  >
    <div class="cursor-pointer" @click="navigateToDashboard()">
      <img class="h-9 block dark:hidden" :src="Logo" />
      <img class="h-9 hidden dark:block" :src="LogoBright" />
    </div>
    <div class="flex flex-grow"></div>

    <div class="mx-3 flex cursor-pointer dark:invert" @click="appStore.actions.toggleDarkMode()">
      <img class="w-3 mr-3" :src="appStore.getters.darkMode.value ? MoonIcon : SunIcon" />
    </div>
    <div class="flex justify-center items-center">
      <router-link
        class="text-black dark:text-white bg-tl-grey-900 dark:bg-tl-grey-200 px-3 py-2 rounded-3xl"
        to="/about"
        >{{ t('about') }}</router-link
      >
      <div
        v-if="isAdmin"
        class="text-black dark:text-white bg-tl-grey-900 dark:bg-tl-grey-200 px-3 py-2 rounded-3xl ml-2"
        @click="admin()"
      >
        {{ t('admin') }}
      </div>
      <div
        v-if="userStore.getters.user.value?._id"
        class="w-px h-9 dark:bg-white bg-tl-grey-100 mx-5"
      ></div>
      <div v-if="userStore.getters.user.value?._id">
        <div
          class="bg-tl-red-400 w-9 h-9 rounded-full flex justify-center items-center font-bold text-white cursor-pointer"
          @click="state.menuIsVisible = !state.menuIsVisible"
        >
          {{ userStore.getters.user.value?.profile.username[0] }}
        </div>
        <div
          v-if="state.menuIsVisible"
          class="absolute z-50 right-2 top-16 bg-tl-grey-100 rounded-xl"
        >
          <SlButton class="" @click.prevent="logout()"> Logout </SlButton>
        </div>
      </div>
      <div
        class="ml-4 flex flex-row justify-center items-center relative text-black dark:text-white"
      >
        <p class="cursor-pointer" @click.stop="selectLanguage = !selectLanguage">
          {{ languageCode }}
        </p>
        <div>
          <img
            class="w-3 mr-3 dark:invert ml-1 h-2 mt-0.5 cursor-pointer"
            @click.stop="selectLanguage = !selectLanguage"
            :src="DownArrow"
          />
        </div>
        <div
          class="absolute bg-white dark:bg-black justify-between z-20 p-1 top-0 left-0 rounded-lg"
          v-if="selectLanguage"
        >
          <div class="relative flex flex-col w-full">
            <p
              class="rounded bg-tl-grey-800 dark:bg-tl-grey-200 p-1 mb-1 text-center cursor-pointer"
              @click="selectALanguage(LanguageCodes.en)"
            >
              English
            </p>
            <p
              class="rounded bg-tl-grey-800 dark:bg-tl-grey-200 p-1 text-center cursor-pointer"
              @click="selectALanguage(LanguageCodes.no)"
            >
              Norsk
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, computed, onBeforeMount, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { useUserStore } from '@/composition/stores/userStore'
import { useAppStore } from '@/composition/stores/appStore'

import SlButton from '@/components/base/SlButton.vue'
import MoonIcon from '@/assets/icons/fontawesome/moon.svg'
import DownArrow from '@/assets/icons/svg/down-arrow.svg'
import SunIcon from '@/assets/icons/fontawesome/sun.svg'
import Logo from '@/assets/teamlearn-logo-black.png'
import LogoBright from '@/assets/teamlearn-logo-white.png'
import { UserRole, type User } from '@database'
import { LanguageCodes } from '@/constants'

const userStore = useUserStore()
const appStore = useAppStore()
const router = useRouter()

const messages = {
  no: { tasks: 'Oppgaver', about: 'Om', admin: 'Admin' },
  en: { tasks: 'Tasks', about: 'About', admin: 'Admin' }
}

const { t } = useI18n({ messages })

const state = reactive({
  user: {} as User,
  menuIsVisible: false,
  darkMode: false
})

const languageCode = appStore.getters.languageCode
const selectLanguage = ref(false)

onBeforeMount(() => {
  const user = userStore.getters.user.value
  if (user) state.user = user
})

const isAdmin = computed(() => {
  const user = userStore.getters.user.value
  return user ? user.profile.role === UserRole.admin : false
})

function selectALanguage(language: LanguageCodes) {
  appStore.actions.setLanguageCode(language)
  selectLanguage.value = false
}

function admin() {
  if (router.currentRoute.value.fullPath.includes('legal')) router.push('/legal/admin')
  else router.push('/nursing/admin')
}

function navigateToDashboard() {
  if (isAdmin.value) router.push('/dashboard')
}

async function logout() {
  await userStore.actions.logout()
  state.menuIsVisible = false

  router.push(`/`)
}
</script>

<style scoped></style>
