<template>
  <div
    class="flex flex-col w-full h-full justify-start items-start pt-2 bg-white dark:bg-tl-grey-100"
  >
    <div class="flex flex-col pl-20 xl:pl-32 2xl:pl-36 pt-5">
      <h1 class="text-black dark:text-white text-7xl xl:text-7xl 2xl:text-8xl w-2/3">
        Group collaboration analytics
      </h1>
      <p class="mt-11 text-tl-grey-300 w-2/3 text-lg lg:text-xl xl:text-2xl">
        Your group&#39;s collaboration data based on meetings and activity in Microsoft Teams.
      </p>
      <p class="mt-4 text-tl-grey-300 w-2/3 text-lg lg:text-xl xl:text-2xl">
        Click on each diagram to explore and analyze details from meetings, project documents and
        reflections on your collaborative process.
      </p>
    </div>
    <div class="mt-16 w-full flex flex-row justify-around">
      <div
        class="size-64 lg:size-72 xl:size-80 2xl:size-96 rounded-xl bg-tl-grey-900 dark:bg-tl-grey-200 flex flex-col items-center cursor-pointer"
        v-for="(type, index) in Object.values(AnalyticsType)"
        :key="index"
        @click="routeTo(type)"
      >
        <div class="flex flex-col justify-start w-full p-5">
          <!--span class="text-tl-lilla-400">Lorem ipsum</span-->
          <p class="text-black dark:text-white text-xl lg:text-xl 2xl:text-2xl">{{ t(type) }}</p>
        </div>
        <div class="flex flex-grow flex-row w-full items-center justify-center">
          <div
            class="rounded-full flex justify-center items-center bg-tl-lilla-400 size-32 lg:size-40 2xl:size-56"
          >
            <img class="h-20 lg:h-28 2xl:h-40" :src="iconByAnalyticsType(type)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTeamStore } from '@/composition/stores/teamStore.js'
import { AnalyticsType } from '@/constants'

import MeetingIcon from '@/assets/icons/users.svg'
import CollaborationIcon from '@/assets/icons/clipboard.svg'
import DocumentsIcon from '@/assets/icons/newspaper.svg'
import router from '@/router'

const messages = {
  no: {
    [AnalyticsType.MeetingAnalytics]: 'Meeting Analytics',
    [AnalyticsType.CollaborationExperience]: 'Collaboration Experience',
    [AnalyticsType.WorkDocuments]: 'Work Documents'
  },
  en: {
    [AnalyticsType.MeetingAnalytics]: 'Meeting Analytics',
    [AnalyticsType.CollaborationExperience]: 'Collaboration Experience',
    [AnalyticsType.WorkDocuments]: 'Work Documents'
  }
}
const { t } = useI18n({ messages })

const teamStore = useTeamStore()

onMounted(async () => {
  selectedTeam.value = teamStore.getters.selectedTeam.value ?? ''
})

const selectedTeam: Ref<string> = ref('')

function iconByAnalyticsType(type: AnalyticsType) {
  switch (type) {
    case AnalyticsType.CollaborationExperience:
      return CollaborationIcon
    case AnalyticsType.MeetingAnalytics:
      return MeetingIcon
    default:
      return DocumentsIcon
  }
}

function routeTo(type: AnalyticsType) {
  const index = Object.values(AnalyticsType).findIndex((t) => t === type)
  const value = Object.keys(AnalyticsType)[index]
  router.push({ name: value })
}
</script>
