<template>
  <div class="bg-white dark:bg-tl-grey-100 h-full flex justify-center items-center flex-col">
    <div class="flex flex-row text-purple-400 text-xl font-medium font-['Inter']">
      Welcome to TeamLearn
    </div>
    <div
      class="flex flex-row text-[44px] font-medium font-inter text-black dark:text-white pt-2 pl-12"
    >
      Start by selecting a team
    </div>
    <div
      v-if="!loading"
      class="flex flex-row justify-center items-center bg-white dark:bg-tl-grey-100 text-white mt-16"
    >
      <div
        class="flex flex-row justify-center items-center mr-8"
        v-for="(team, index) in teamGetters.allTeams.value"
        :key="index"
      >
        <div
          class="justify-center items-center cursor-pointer bg-purple-400 opacity-80 hover:opacity-100 rounded-lg text-black text-[34px] font-normal font-['Inter'] px-8 py-4 mb-8"
          @click="selectTeam(team)"
        >
          <p>{{ team }}</p>
        </div>
      </div>
      <div
        v-if="teamGetters.allTeams.value.length === 0"
        class="w-full h-auto flex flex-col items-center"
      >
        <p class="text-3xl text-black dark:text-white">
          No groups found for this user. Please check with your teacher.
        </p>
        <div
          class="text-black cursor-pointer bg-tl-lilla-400 w-24 mt-8 p-2 text-center rounded-xl h-10"
        >
          <p @click="logout()">Logg out</p>
        </div>
      </div>
    </div>
    <div v-else class="flex flex-col items-center">
      <p>Loading {{ loading }}</p>
      <PulseLoader class="mt-6" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useTeamStore } from '@/composition/stores/teamStore.js'
import { useUserStore } from '@/composition/stores/userStore.js'
import router from '@/router'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

const { getters: teamGetters, actions: teamActions } = useTeamStore()
const { actions: userActions } = useUserStore()

const loading = ref('')
const route = useRoute()

onMounted(async () => {
  loading.value = 'teams'
  if (route.query['testing']) {
    teamActions.setIsTesting(true)
  }
  try {
    await teamActions.getTeamMembers()

    const teams = teamGetters.allTeams.value
    if (teams.length === 1) selectTeam(teams[0])
  } catch (error) {
    console.log(error)
  }
  loading.value = ''
})

const selectTeam = async (team: string) => {
  loading.value = 'messages'
  teamActions.selectTeam(team)
  await teamActions.getMessages()
  loading.value = ''
  router.push('/legal/dashboard')
}

async function logout() {
  await userActions.logout()
  router.push(`/`)
}
</script>
