import { createI18n } from 'vue-i18n'
import { globalTranslations } from './translations.js'

//import useCommonStore from '@/composables/common'

//const common = useCommonStore()
let locale = 'no' // common.getters.languageCode.value

switch (locale) {
  case 'nb-NO':
  case 'nn-NO':
  case 'no':
    locale = 'no'
    break
  case 'en':
    locale = 'en'
    break
  default:
    locale = 'en'
}

const i18n = createI18n({
  locale,
  globalInjection: true,
  fallbackLocale: {
    'nb-NO': ['no'],
    nb: ['no'],
    'nn-NO': ['no'],
    nn: ['no'],
    'en-AU': ['en'],
    'en-GB': ['en']
  },
  legacy: false,
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  fallbackWarn: false,
  missingWarn: false,
  messages: globalTranslations
})

export { i18n }
