<!-- Copyright 2020, 2021 Richard Nesnass, Sharanya Manivasagam and Ole Smørdal

 This file is part of VIVA.

 VIVA is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 VIVA is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with VIVA.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div
    class="flex flex-row justify-center bg-tl-grey-800 dark:bg-tl-green-100 rounded-20px"
    :class="[!showDetails ? 'h-28' : 'h-48']"
  >
    <div class="flex flex-col w-full pl-4 mt-6">
      <div class="pt-4 pb-6 flex flex-row px-6 items-center">
        <div class="w-full h-8 relative border-black dark:border-white border-2 rounded-2xl">
          <div class="pointer-events-none">
            <div
              v-for="e of visibleKeyEvents"
              :key="e.id"
              :style="calculateEventLocation(e)"
              class="absolute border-l-2 border-black dark:border-yellow-300 border-dotted first:border-none last:border-none group"
              :class="[noEvents || !showDetails ? 'h-7' : 'h-28']"
            >
              <div class="absolute -top-7 -left-4 group-first:left-2 group-last:-left-10">
                <span class="text-black dark:text-yellow-300 font-bold text-xs">
                  {{ secondsToTime(e.start) }}</span
                >
              </div>
              <div
                v-if="
                  (selectedTab == TabName.StressManagement || selectedTab == TabName.General) &&
                  (e.name == 'Handover End' || e.name == 'Patient Crisis')
                "
                class="absolute -bottom-6 -left-14 ml-4"
              >
                <p class="text-black dark:text-yellow-300 font-bold text-xs w-32">
                  {{ t(e.name) }}
                </p>
              </div>
              <div
                class="absolute top-0.5 left-0 ml-2 w-36 text-black dark:text-white bg-tl-grey-800 dark:bg-tl-green-100"
              >
                <span v-if="e.name === 'Simulation Start'">{{ t('Handover') }}</span>
                <span v-else-if="e.name === 'Handover End'">{{ t('Stabilisation') }}</span>
                <span v-else-if="e.name === 'Patient Crisis'">{{ t('Post-deterioration') }}</span>
              </div>
            </div>
          </div>
          <div
            v-for="e in visibleEvents"
            :key="e.id"
            class="absolute highlight-horizontal"
            :style="calculateEventLocation(e)"
          >
            <div class="relative top-24 odd:top-16" v-if="showDetails">
              <div
                class="rounded-2xl text-center flex flex-row px-2"
                :class="[
                  selectedEvent && selectedEvent.id == e.id
                    ? 'bg-yellow-100 text-black'
                    : 'bg-slate-700 text-white'
                ]"
              >
                <!--div class="w-2 mr-2"></div-->
                <!--div
                  class="w-2 rounded-l-2xl mr-2"
                  :class="[e.status === EventStatus.True ? 'bg-green-500' : 'bg-red-500']"
                ></div-->
                <p class="cursor-pointer" @click="selectEvent(e)">
                  {{ e.type == EventType.Stress ? t('h') : `${t('event')}&nbsp;` }}{{ e.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="selectedTab != TabName.General"
          @click="showDetails = !showDetails"
          class="font-bold text-3xl ml-2 text-black dark:text-white border-2 border-black dark:border-white rounded-full size-8 flex items-center justify-center"
        >
          <img class="w-3 dark:invert" :class="{ 'rotate-180': showDetails }" :src="ArrowIcon" />
        </div>
      </div>
    </div>
    <div
      v-if="selectedTab != TabName.General && showDetails"
      class="flex flex-col justify-center w-64 h-full"
    >
      <div class="flex flex-row items-center mb-1" v-for="(s, i) in validSpeakers" :key="i">
        <div
          class="p-2 rounded-md h-8 text-white flex flex-row items-center justify-center w-10 mr-2"
          :class="{
            'bg-tl-student-ln': s == Speaker.LeadNurse || s == Speaker.AS,
            'bg-tl-student-sn1': s == Speaker.SupportNurse1 || s == Speaker.S1,
            'bg-tl-student-sn2': s == Speaker.SupportNurse2 || s == Speaker.S2,
            'bg-tl-student-sn3': s == Speaker.SupportNurse3 || s == Speaker.S3
          }"
        >
          <p class="text-sm">{{ t(s.toLowerCase()) }}</p>
        </div>
        <p class="text-xs font-bold text-black dark:text-white">
          {{ t(s.toLowerCase() + '_fullname') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import type { TLEvent } from '@/models/nursing'
import { useSessionStore } from '@/composition/stores/sessionStore.js'
import { secondsToTime } from '@/utilities'
import { Speaker, TabName, EventType } from '@/constants'
import { computed, ref, type PropType } from 'vue'
import ArrowIcon from '@/assets/icons/fontawesome/arrow-down-regular.svg'

const messages = {
  en: {
    'Handover End': 'Handover End',
    'Patient Crisis': 'Patient Crisis',
    Handover: 'Handover',
    Stabilisation: 'Stabilisation',
    'Post-deterioration': 'Post-deterioration',
    h: 'E-'
  },
  no: {
    'Handover End': 'Rapportslutt',
    'Patient Crisis': 'Pasientforverring',
    Handover: 'Overlevering',
    Stabilisation: 'Stabilisering',
    'Post-deterioration': 'Post-deterioration',
    h: 'H-'
  }
}
const { t } = useI18n({ messages })

const { actions: sessionActions, getters: sessionGetters } = useSessionStore()
const speaker = sessionGetters.selectedSpeaker
const events = sessionGetters.events
const keyEvents = sessionGetters.keyEvents
const validSpeakers = [
  Speaker.LeadNurse,
  Speaker.SupportNurse1,
  Speaker.SupportNurse2,
  Speaker.SupportNurse3
] //sessionGetters.validSpeakers
const selectedEvent = sessionGetters.selectedEvent
const showDetails = ref(false)

const props = defineProps({
  selectedTab: { type: String as PropType<TabName>, required: true }
})

const visibleEvents = computed(() => {
  if (props.selectedTab == TabName.General) return []
  else return events.value.filter((e) => e.owner === speaker.value || speaker.value == Speaker.All)
})

const noEvents = computed(() => visibleEvents.value.length === 0)

const visibleKeyEvents = computed(() =>
  keyEvents.value.filter((e) => e.name != 'Calling Start' && e.name != 'Calling End')
)

function selectEvent(e: TLEvent) {
  const currentlySelectedEvent = sessionGetters.selectedEvent.value
  if (currentlySelectedEvent?.id == e.id) sessionActions.setSelectedEvent()
  else sessionActions.setSelectedEvent(e)
}

function calculateEventLocation(e: TLEvent) {
  // X
  const duration = sessionGetters.selectedSession.value?.duration ?? 1
  const x = (e.start / duration) * 100
  return `left: ${x}%;`
}
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style scoped>
.volume-slider {
  --slider-handle-bg: #2a8805;
  --slider-handle-width: 16px;
  --slider-handle-height: 16px;
  --slider-height: 8px;
  --slider-vertical-height: 4rem;
  --slider-bg: #444;
  --slider-connect-bg: #2a8805;
  --slider-tooltip-bg: #2a8805;
}
.volume-slider ::v-deep(.slider-handle-upper) {
  --slider-tooltip-line-height: 2rem !important;
}
/* .volume-slider ::v-deep(.slider-handle) {
  right: calc(
    var(--slider-handle-height, 16px) / 2 * -1 - var(--slider-height, 6px) / 2 * -1
  ) !important;
} */
.trimming-slider {
  --slider-handle-bg: #059fff;
  --slider-handle-width: 16px;
  --slider-handle-height: 16px;
  --slider-height: 3px;
  --slider-vertical-height: 4rem;
  --slider-bg: rgba(163, 185, 255, 0.2);
  --slider-connect-bg: #059fff;
  --slider-tooltip-bg: #059fff;
  --slider-tooltip-font-size: 0.7rem;
  --slider-tooltip-font-weight: 200;
  --slider-tooltip-line-height: 0.5rem;
}
.trimming-slider ::v-deep(.slider-handle-upper) {
  --slider-tooltip-line-height: 2rem !important;
}

.playing-slider {
  --slider-handle-bg: #2a8805;
  --slider-handle-width: 16px;
  --slider-handle-height: 16px;
  --slider-height: 3px;
  --slider-vertical-height: 4rem;
  --slider-bg: rgba(163, 185, 255, 0.2);
  --slider-connect-bg: #2a8805;
  --slider-tooltip-bg: #2a8805;
}
.playing-slider ::v-deep(.slider-handle-upper) {
  --slider-tooltip-line-height: 2rem !important;
}

.layout {
  background: #f5f7f9;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}
.playbackVideo {
  margin: 0;
  width: 100%;
  background-color: green;
  z-index: 1;
}
.playbackVideoSmall {
  margin: auto;
  width: 80%;
  background-color: green;
  z-index: 1;
}
/* video::-webkit-media-controls-enclosure {
  display: none !important;
} */
#videoContainer {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

#segments {
  height: 50%;
}
</style>
