import { type ComputedRef, computed, reactive } from 'vue'
import { type StoreResponse } from '@/models/main.js'
import useApiService from '@/apiRequest.js'
import type { User } from '@database'
import { ErrorCode, XHR_REQUEST_TYPE, XHR_CONTENT_TYPE } from '@/constants.js'
import useDialogStore from '@/composition/stores/dialogStore.js'

const dialogStore = useDialogStore()
const apiService = useApiService()

interface State {
  user: User | undefined
  isRegistered: boolean
}

const state: State = reactive({
  user: undefined,
  isRegistered: false
})

interface Actions {
  reset: () => void
  logout: () => Promise<void>
  getUserDetails: () => Promise<StoreResponse>
}

interface Getters {
  user: ComputedRef<User | undefined>
}

interface ServiceInterface {
  state: State
  actions: Actions
  getters: Getters
}

function useUserStore(): ServiceInterface {
  const actions = {
    reset(): void {
      state.user = undefined
      state.isRegistered = false
      sessionStorage.removeItem('team') // remove item used to persist team (LegalTech)
      sessionStorage.removeItem('group') // remove persisted group (Nursing)
      sessionStorage.removeItem('session') // remove persisted session (Nursing)
      sessionStorage.removeItem('selectedTab')
    },

    async getUserDetails(): Promise<StoreResponse> {
      // if (state.user?._id) return { success: true }
      const res = await apiService.apiRequest<User>({
        errorCode: ErrorCode.USER,
        route: `/api/user`,
        method: XHR_REQUEST_TYPE.GET,
        credentials: true,
        contentType: XHR_CONTENT_TYPE.JSON
      })
      if (res.error) {
        res.error.message = 'User query contains no records or invalid session'
        dialogStore.actions.handleError(res)
        actions.reset() // reset user store to initial state
      } else {
        state.user = res.data
        return { success: true }
      }
      return { success: false, error: res.error }
    },

    async logout(): Promise<void> {
      await apiService.apiRequest({
        errorCode: ErrorCode.LOGIN,
        route: `/auth/logout`,
        method: XHR_REQUEST_TYPE.GET,
        credentials: true
      })
      this.reset() // reset values whenever logout is invoked
    }
  }

  const getters = {
    get user(): ComputedRef<User | undefined> {
      return computed(() => state.user)
    }
  }

  return { state, actions, getters }
}

export { useUserStore }
